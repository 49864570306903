export interface DocumentUploadDto {
	file: File;
	comment: string;
	type: string;
	bookingId: number;
}

export enum DOCUMENT_UPLOAD_TYPES {
	DEVIS = "DEVIS",
	FACTURE = "FACTURE",
	INTERNE = "INTERNE",
	BON_DE_COMMANDE = "BON_DE_COMMANDE",
	AUTRE = "AUTRE"
}

export const DOCUMENT_UPLOAD_TYPES_OBJ = {
	[DOCUMENT_UPLOAD_TYPES.DEVIS]: { order: 0, label: "Devis" },
	[DOCUMENT_UPLOAD_TYPES.FACTURE]: { order: 1, label: "Facture" },
	[DOCUMENT_UPLOAD_TYPES.INTERNE]: { order: 2, label: "Interne" },
	[DOCUMENT_UPLOAD_TYPES.BON_DE_COMMANDE]: { order: 3, label: "Bon de commande" },
	[DOCUMENT_UPLOAD_TYPES.AUTRE]: { order: 4, label: "Autre" }
}
