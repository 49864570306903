export const CONSTANTS = {
	YOU_SURE: 'Êtes-vous sûr ?',
	BOOKING_MIN_DELAY: 14,
    CALENDAR_LOCALE: "fr-FR",
    CALENDAR_WEEK_STARTS_ON: 1,
    CALENDAR_START_HOUR: 7,
	CALENDAR_END_HOUR: 21,
    CALENDAR_HOUR_SEGMENTS: 2,
    CALENDAR_SEGMENT_HEIGHT: 24,
    DATE_TRANSACTION_FORMAT: "YYYY-MM-DD",
    HOUR_TRANSACTION_FORMAT: "HH:mm:ss",
    DATE_HOUR_TRANSACTION_FORMAT: "YYYY-MM-DD[T]HH:mm:ss",
	BOOKING_LIST_MAX_SIZE: 20,
	SWITCH_BOOKING: "Ouvrir sur la demande correspondante ?"
}
