<div class="amiante-liste" [ngClass]="{'clickable' : !!this.options.listOptions?.length}" *ngIf="isEditing === false" (click)="enterEditMode()">
	<ng-container *ngIf="value?.length > 0">
		<div *ngFor="let val of value" [ngStyle]="options.styles">
			<span>{{ val }}</span>
			<BR />
		</div>
	</ng-container>

	<ng-container *ngIf="value?.length === 0 || !value">
		<div style="color: green">
			<span>Fibres d'amiante non-détectées</span>
		</div>
	</ng-container>
</div>

<div *ngIf="isEditing === true">
	<div #form>
		<div *ngFor="let o of options.listOptions">
			<input class="amianteType" type="checkbox" [id]="o" [name]="o" [value]="o" [checked]="value.includes(o)" />
			<label>&nbsp;{{o}}</label>
		</div>
		<div>
			<button class="button is-primary is-small is-inverted" (click)="updateSelection()">
				Sauvegarder
			</button>
		</div>
	</div>
</div>
