<div id="modal-container">

	<h2>Lien public à distribuer aux ayants droits</h2>
	<div class="buttons">
		<a style="font-size: 18px;" target="_blank" [href]="publicLink">{{publicLink}}</a>
		<button class="button" (click)="copy()">
			<mat-icon>crop_free</mat-icon>
			<span>Copier</span>
		</button>
	</div>


	<a (click)="goEvent()" style="margin: 30px 0 20px 0">Accéder à la gestion des réservations</a>
</div>
