<div id="modal">

	<div class="title">
		<h1>Offres pour l'activité {{ activity.title }}</h1>

		<button class="button is-primary" [ngClass]="{'is-fullwidth' : appService.isMobile() }" (click)="clickNewOffer()">
			Ajouter une offre
		</button>
	</div>


	<table class="table" *ngIf="offers.length > 0">
		<thead>
			<tr>
				<th>&nbsp;</th>
				<th *ngIf="activity.activityType != activity_types.AUTRE">
					<span *ngIf="activity.activityType === activity_types.COLLECTIVE || activity.activityType === activity_types.INDIVIDUELLE">Durée de la séance</span>
					<span *ngIf="activity.activityType === activity_types.FORMATION">Durée de la formation</span>
					<span *ngIf="activity.activityType === activity_types.FICHE">Nombre de fiches</span>
				</th>
				<th *ngIf="activity.activityType === activity_types.COLLECTIVE">Nombre de participants max</th>
				<th *ngIf="activity.activityType === activity_types.INDIVIDUELLE">Nombre de créneaux individuels</th>
				<th>Prix de la prestation</th>
				<th *ngIf="activity.activityType !== activity_types.FICHE">Durée de la pause</th>
				<th *ngIf="activity.activityType !== activity_types.FICHE">
					Prix par
					<span *ngIf="activity.activityType === 'INDIVIDUELLE'">créneau individuel</span>
					<span *ngIf="activity.activityType !== 'INDIVIDUELLE'">participant</span>
				</th>
				<th colspan="2">Actions</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let o of offers; let i = index;">
				<ng-container *ngIf="!o.isEditing">
					<td>
						<mat-icon style="color: #D49A56;" *ngIf="i == 0" title="Offre sélectionnée par défaut dans le panier client.">star</mat-icon>
					</td>
					<td>{{ o.sessionDuration }} {{ activity.activityType === activity_types.FICHE ? 'fiches' : 'minutes'}}</td>
					<td *ngIf="activity.activityType == activity_types.COLLECTIVE">{{ o.maxPoeple }} participants max</td>
					<td *ngIf="activity.activityType == activity_types.INDIVIDUELLE">{{ o.maxPoeple }} créneaux</td>
					<td>{{ o.price }}€</td>
					<td *ngIf="activity.activityType !== activity_types.FICHE">{{ o.breakDuration }} minutes</td>
					<td *ngIf="activity.activityType !== activity_types.FICHE">{{ o.pricePerEmployee }}€</td>
					<td>
						<button class="button is-small is-light is-danger" tooltipStr="Supprimer l'offre" (click)="deleteOffer(o)" [disabled]="offers.length === 1">
							<mat-icon>delete</mat-icon>
						</button>
					</td>
					<td>
						<button class="button is-small is-light is-info" style="margin-left: 4px;" tooltipStr="Modifier l'offre" (click)="setEdition(o)">
							<mat-icon>edit</mat-icon>
						</button>
					</td>
				</ng-container>

				<ng-container *ngIf="o.isEditing">
					<td>&nbsp;</td>
					<td>
						<input *ngIf="activity.activityType !== activity_types.INDIVIDUELLE" type="number" [(ngModel)]="o.sessionDuration">
						<select *ngIf="activity.activityType === activity_types.INDIVIDUELLE" [(ngModel)]="o.sessionDuration">
							<option [value]="180">180 minutes (3 heures)</option>
							<option [value]="420">420 minutes (7 heures)</option>
						</select>
					</td>
					<td *ngIf="activity.activityType !== activity_types.FICHE"><input type="number" [(ngModel)]="o.maxPoeple"></td>
					<td><input type="number" [(ngModel)]="o.price"></td>
					<td *ngIf="activity.activityType !== activity_types.FICHE"><input type="number" [(ngModel)]="o.breakDuration"></td>
					<td *ngIf="activity.activityType !== activity_types.FICHE">{{(o.price / o.maxPoeple).toFixed(2) + "€"}}</td>
					<td>
						<button class="button is-small is-light" tooltipStr="Annuler la modification" (click)="cancelEdition()">
							<mat-icon>close</mat-icon>
						</button>
					</td>
					<td>
						<button class="button is-small is-light is-info" tooltipStr="Confirmer la modification" (click)="saveOffer()">
							<mat-icon>check</mat-icon>
						</button>
					</td>
				</ng-container>
			</tr>
		</tbody>
	</table>

	<p *ngIf="offers.length === 0">Aucune offre pour le moment.</p>

	<div id="buttons">
		<button class="button" (click)="close()" [ngClass]="{'is-fullwidth' : appService.isMobile() }">
			Fermer
		</button>
	</div>
</div>
