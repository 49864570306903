<div id="table-container">
	<table class="table is-striped is-hoverable is-fullwidth" >
		<thead #thead [ngClass]="{ 'is-scrolling' : isScrolling }">
			<tr>
				<th *ngFor="let col of config.columns"
					[ngStyle]="{'max-width' : col.maxWidth ? col.maxWidth: 'default',
								'min-width' : col.minWidth ? col.minWidth: 'default',
								'text-align': col.textAlign ? col.textAlign : 'default'}">
					<div class="head">
						<div>{{col.title}}</div>
						<mat-icon
							*ngIf="col.sortable"
							(click)="sortBy(col.fieldName)"
							[ngClass]="{'active': col.fieldName === currentSort.name }">
							{{col.fieldName === currentSort.name && currentSort.direction === 'desc' ? 'arrow_drop_up' : 'arrow_drop_down'}}
						</mat-icon>
					</div>
				</th>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let line of dataSource"
				[ngClass]="{'disabled': !!config.disableRule ? config.disableRule(line) : false}"
				>
				<td *ngFor="let col of config.columns"
					[ngStyle]="{'max-width' : col.maxWidth ? col.maxWidth: 'default',
								'min-width' : col.minWidth ? col.minWidth: 'default',
								'text-align': col.textAlign ? col.textAlign : 'default'}">
					<ng-container appDynamicField
						[value]="line[col.fieldName]"
						[type]="col.type"
						[propertyField]="line[col.param]"
						[objectValue]="line"
						[internalAlign]="col.textAlign">
					</ng-container>
				</td>
			</tr>
		</tbody>
	</table>
</div>




