
<div id="menu-mobile"
	class="is-hidden-desktop"
	*ngIf="appService.menuIsOpen() && userService.isLoggedIn()"
	>
	<div class="backdrop" (click)="appService.menuIsOpen.set(false)"></div>
	<app-menu></app-menu>
</div>

<header class="columns is-gapless is-mobile">
	<div class="column is-1-desktop is-half-mobile">
		<img
			src="../../assets/images/logo-jmactiv.png"
			alt="JMActiv Entreprise"
			(click)="onLogoClick()"
			id="app-icon"
		/>
	</div>

	<div id="menu-desktop" class="column is-hidden-touch">
		<app-menu *ngIf="userService.isLoggedIn()"></app-menu>
	</div>

	<div id="right" class="column is-half-mobile is-5-tablet is-2-desktop" [ngClass]="{'is-hidden-desktop' : userService.isLoggedIn()}">
		<!-- CONNEXION -->
		<button class="button is-primary is-large"
			*ngIf="!userService.currentUser() && !window.location.href.includes('connexion')"
			[routerLink]="['/connexion']">
			Connexion
		</button>

		<!-- MOBILE -->
		<button class="button is-primary is-inverted is-medium is-hidden-desktop" *ngIf="userService.isLoggedIn()" (click)="toggleMenu()">
			<mat-icon>menu</mat-icon>
		</button>
	</div>
</header>
