import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { ActivityDto } from 'src/app/modules/shared/dtos/activity/activity.dto';
import { CreateOfferDto } from 'src/app/modules/shared/dtos/offer/create-offer.dto';
import { OfferDto } from 'src/app/modules/shared/dtos/offer/offer.dto';
import { ACTIVITY_TYPES } from 'src/app/modules/shared/enums/activity-type.enum';
import { ActivityService } from 'src/app/modules/shared/services/activity.service';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { CONSTANTS } from 'src/constants';


@Component({
	selector: 'app-activity-offers',
	templateUrl: './activity-offers-modal.component.html',
	styleUrls: ['./activity-offers-modal.component.scss'],
})
export class ActivityOffersModalComponent implements OnInit {

	readonly activity_types = ACTIVITY_TYPES;

	activity: ActivityDto;
	offers: OfferDto[] = [];
	form: FormGroup;
	newOffer: OfferDto;
	offerBackup: string;

	constructor(
		public dialogRef: DialogRef,
		public appService: AppService,
		private activityService: ActivityService,
		private helperService: HelperService
	) { }


	ngOnInit() {
		this.activity = this.dialogRef.config.data.activity;
		this.offers = this.activity.offers.map((o) => {
			return {
				...o,
				isEditing: false
			}
		});
	}

	async deleteOffer(o: OfferDto) {
		if (confirm(CONSTANTS.YOU_SURE)) {
			await firstValueFrom(this.activityService.deleteOffer(o.id))
			const index = this.offers.findIndex(offer => offer.id === o.id);
			this.offers.splice(index, 1);
		}
	}

	clickNewOffer() {
		this.offers.forEach(o => o.isEditing = false);
		this.offers.push({
			maxPoeple: 10,
			sessionDuration: 60,
			price: 0,
			breakDuration: 0,
			isEditing: true
		})
	}

	async saveOffer() {
		const offer = this.offers.find(o => o.isEditing === true);
		if (offer.id) {
			this.updateOffer(offer);
		} else {
			this.createOffer(offer);
		};
	}

	setEdition(o: OfferDto): void {
		this.offerBackup = JSON.stringify(o);
		o.isEditing = true;
	}

	cancelEdition(): void {
		const index = this.offers.findIndex(o => o.isEditing === true);
		if (this.offerBackup) {
			this.offers[index] = JSON.parse(this.offerBackup)
			delete this.offerBackup;
		} else {
			this.offers.splice(index, 1);
		}
	}

	close(): void {
		this.dialogRef.config.data.saveCloseCallback();
		this.dialogRef.close();
	}

	private async updateOffer(offer: OfferDto) {
		const result = await firstValueFrom(this.activityService.updateOffer(offer));
		this.afterRequest(result);
	}

	private async createOffer(offer: CreateOfferDto) {
		offer.activityId = this.activity.id;
		const updatedOffer: OfferDto = await firstValueFrom(this.activityService.createOffer(offer));
		this.afterRequest(updatedOffer);
	}

	private afterRequest(offer: OfferDto) {
		this.offers.forEach(o => {
			if (o.isEditing) {
				o.isEditing = false;
				o.id = offer.id;
				o.pricePerEmployee = offer.pricePerEmployee
			}
		})

		this.offers = this.helperService.orderBy(this.offers, "pricePerEmployee")
	}
}

