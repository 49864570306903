<div id="modal">
	<img src="../../../../../../assets/images/check_round.png" />

	<h1>Félicitations !</h1>
	<p>L'activité a bien été ajoutée à votre réservation</p>

	<button class="button is-primary is-fullwidth" (click)="go('catalogue')">
		<mat-icon>add</mat-icon>
		Ajouter d'autres activités à ma sélection
	</button>

	<button class="button is-fullwidth" (click)="go('nouvelle-demande')">
		Aller à ma réservation
	</button>
</div>
