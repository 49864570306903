import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarDayViewComponent, CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewComponent, CalendarView, CalendarWeekViewComponent } from 'angular-calendar';
import { Observable, Subject, Subscription, filter, firstValueFrom, skip } from 'rxjs';
import { EventBaseDto } from 'src/app/modules/shared/dtos/event/event.dto';
import { EventBooking } from 'src/app/modules/shared/models/event-booking';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { EventService } from 'src/app/modules/shared/services/event.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { CONSTANTS } from 'src/constants';
import { EventCalendarModalComponent } from '../../../modals/event-calendar-modal/event-calendar-modal.component';
import { ACTIVITY_TYPES } from 'src/app/modules/shared/enums/activity-type.enum';
import { RegionalCommitteeBaseDto } from 'src/app/modules/shared/dtos/regional-comittee.dto';


@Component({
  selector: 'app-organiser',
  templateUrl: './organiser.component.html',
  styleUrl: './organiser.component.scss'
})
export class OrganiserComponent implements OnInit, OnDestroy {

	readonly CONSTANTS = CONSTANTS;
	readonly CalendarView = CalendarView;

    @ViewChild('monthComponent') monthComponent: CalendarMonthViewComponent;
    @ViewChild('weekComponent') weekComponent: CalendarWeekViewComponent;
    @ViewChild('dayComponent') dayComponent: CalendarDayViewComponent;

    view: CalendarView = CalendarView.Week;
    viewDate: Date = new Date();
    refresh: Subject<any> = new Subject();
	events: CalendarEvent[] = [];
	activeDayIsOpen = false;
	currentBookingId: number;
	currentPeriod: { start: Date, end: Date };
	dataLoaded = false;
	currentRegion$: Observable<RegionalCommitteeBaseDto>;
	currentRegionId: number;
	serverEvents: EventBooking[] = [];

	private subscriptions: Subscription[] = [];

	constructor(
		public helper: HelperService,
		private activatedRoute: ActivatedRoute,
		private bookingService: BookingService,
		private userService: UserService,
		private router: Router,
		private dialog: MatDialog,
		private eventService: EventService
	) {
		this.currentRegion$ = toObservable(this.userService.currentRegion);
	}

	ngOnInit(): void {
		this.getBookingParams();
		this.watchRegionChanged();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	setView(view: CalendarView) {
        this.view = view;
		this.dataLoaded = false;
	}

	navigateCalendar() {
		this.events = [];
		this.dataLoaded = false;

	}

	setPeriod(event: { period: {start: Date, end: Date} }): void {
		this.currentPeriod = event.period;
		if (!this.dataLoaded) {
			this.loadEvents();
		}
	}

	eventTimesChanged(calendarEvent: CalendarEventTimesChangedEvent) {
		this.updateCalendar(calendarEvent);
		this.updateOnServer(calendarEvent);
	}

	async eventClicked(evt: any) {
		const correspondingEvent = this.serverEvents.find(e => e.id === evt.event.id)
		if (correspondingEvent.bookingId !== this.currentBookingId) {
			if (confirm(CONSTANTS.SWITCH_BOOKING)) {
				this.router.navigateByUrl('dashboard/demandes/'+correspondingEvent.bookingId);
				this.getBookingParams();
				this.dataLoaded = false;
				this.refresh.next(null);
			}
		} else if (correspondingEvent.activityType === ACTIVITY_TYPES.INDIVIDUELLE) {
			this.dialog.open(EventCalendarModalComponent, {
				data: {
					eventId: correspondingEvent.id
				}
			})
		}
	}

	async resetCompletely() {
		if (confirm(CONSTANTS.YOU_SURE + '\n' + 'Les dates et offres de la demande seront restaurées tel que le client les a formulé.')) {
			await firstValueFrom(this.bookingService.reset(this.currentBookingId));
			this.dataLoaded = false;
			this.loadEvents();
		}
	}

	private async updateCalendar(calendarEvent: CalendarEventTimesChangedEvent) {
		const eventId = calendarEvent.event.id;
		const correspondingEvent = this.events.find(e => e.id === eventId);
		correspondingEvent.start = calendarEvent.newStart;
		correspondingEvent.end = calendarEvent.newEnd;
		this.refresh.next(null);
	}

	private async updateOnServer(calendarEvent: CalendarEventTimesChangedEvent) {
		const eventId = calendarEvent.event.id;
		const correspondingEvent = this.serverEvents.find(se => se.id === eventId);
		correspondingEvent.date = calendarEvent.newStart;
		await firstValueFrom(this.eventService.updateEvent(correspondingEvent.dto));
	}

	async loadEvents() {
		this.dataLoaded = true;
		this.serverEvents = (await firstValueFrom(
			this.eventService.getEventsByPeriod(
				this.currentPeriod.start,
				this.currentPeriod.end,
				this.userService.currentRegion()?.id
			)
		))
		this.events = this.serverEvents.map(event => event.getCalendarEvent(this.currentBookingId));
	}

	private getBookingParams(): void {
		this.subscriptions.push(
			this.activatedRoute.parent.data.pipe(
				filter(data => data.booking.id !== this.currentBookingId)
			).subscribe(data => {
				this.currentBookingId = data.booking.id;
				const sortedEvents = data.booking.events.sort((a: EventBaseDto, b: EventBaseDto) => {
					return new Date(a.date).getTime() - new Date(b.date).getTime();
				})
				this.viewDate = new Date(sortedEvents[0].date);
				this.navigateCalendar();
			})
		)
	}

	private watchRegionChanged(): void {
		this.subscriptions.push(
			this.currentRegion$.pipe(skip(1)).subscribe((region) => this.router.navigateByUrl("dashboard/demandes"))
		)
	}
}
