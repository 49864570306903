<section>
	<div class="header">
		<h1>Documents</h1>

		<ng-container *ngIf="userService.currentUser().role !== ROLES.CLIENT">
			<button class="button is-primary" (click)="fileInput.click()" *ngIf="!formOpen">
				<mat-icon>add</mat-icon>
				<input hidden #fileInput type="file" (change)="selectFile($event)">
				<span>Ajouter un document</span>
			</button>
		</ng-container>
	</div>

	<form class="columns" [formGroup]="form" *ngIf="formOpen">
		<div class="column is-4">
			<mat-form-field>
				<mat-label>Nom du document</mat-label>
				<input matInput formControlName="fileName">
			</mat-form-field>
		</div>
		<div class="column is-3">
			<mat-form-field>
				<mat-label>Type de fichier</mat-label>
				<mat-select formControlName="fileType" (selectionChange)="documentTypeChanged()">
					<mat-option *ngFor="let option of DOCUMENT_UPLOAD_OPTIONS" [value]="option.value">{{option.label}}</mat-option>
				  </mat-select>
			</mat-form-field>
		</div>
		<div class="column is-3">
			<mat-form-field *ngIf="form.get('fileType').value !== DOCUMENT_UPLOAD_TYPES.BON_DE_COMMANDE">
				<mat-label>Commentaire</mat-label>
				<input matInput formControlName="comment">
			</mat-form-field>
			<mat-form-field *ngIf="form.get('fileType').value === DOCUMENT_UPLOAD_TYPES.BON_DE_COMMANDE">
				<mat-label>N° bon de commande</mat-label>
				<input matInput formControlName="purchaseOrder">
			</mat-form-field>
		</div>
		<div class="column is-flex is-justify-content-space-evenly is-align-items-center">
			<button class="button is-small is-light is-info" style="margin-bottom: 1rem;" [disabled]="!form.valid" (click)="uploadDocument()">
				<mat-icon>check</mat-icon>
			</button>
			<button class="button is-small is-light" style="margin-bottom: 1rem;" (click)="formOpen = false">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</form>

	<p *ngIf="documents.length === 0">Aucun.</p>
	<table class="table" *ngIf="documents.length > 0">
		<thead>
			<tr>
				<td>Date</td>
				<td>Fichier</td>
				<td>Auteur</td>
				<td>Type</td>
				<td>Commentaire</td>
				<td *ngIf="userService.currentUser().role !== ROLES.CLIENT">&nbsp;</td>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let d of documents">
				<td>{{helper.dateFr(d.date)}}</td>
				<td><a (click)="download(d)">{{d.fileName}}</a></td>
				<td>{{d.author}}</td>
				<td>{{ getDocumentTypeLabel(d.type) }}</td>
				<td>{{d.comment}}</td>
				<td *ngIf="userService.currentUser().role !== ROLES.CLIENT">
					<button class="button is-small is-danger is-inverted" (click)="deleteDoc(d.id)">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</section>
