
import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { firstValueFrom } from 'rxjs';
import { CommuneDto } from 'src/app/modules/shared/dtos/commune.dto';
import { RegionalCommitteeBaseDto } from 'src/app/modules/shared/dtos/regional-comittee.dto';
import { UserDto } from 'src/app/modules/shared/dtos/user/user.dto';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { JOBS } from 'src/app/modules/shared/models/jobs.list';
import { GeoService } from 'src/app/modules/shared/services/geo.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { JmaService } from 'src/app/modules/shared/services/jma.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { CONSTANTS } from 'src/constants';

@Component({
	selector: 'app-utilisateur.modal',
	templateUrl: './utilisateur.modal.html',
	styleUrls: ['./utilisateur.modal.scss'],
})

export class UtilisateurModal implements OnInit {

	@ViewChild('cityField', { read: MatAutocompleteTrigger }) cityField: MatAutocompleteTrigger;

	readonly JOBS = JOBS;
	readonly ROLES = ROLES;
	communes: CommuneDto[] = [];
	form!: FormGroup;
	resForm!: FormGroup;
	comiteRegionals: RegionalCommitteeBaseDto[] = [];
	emailExists: boolean = false;
	isArchived: boolean;

	constructor(
		public dialogRef: DialogRef,
		private formBuilder: FormBuilder,
		private utilisateurService: UserService,
		private jmaService: JmaService,
		private geoService: GeoService,
		private helper: HelperService,
		private cdr: ChangeDetectorRef
	) { }

	async ngOnInit() {
		this.comiteRegionals = await firstValueFrom(this.jmaService.getAllComiteRegionals());
		this.initForm(await firstValueFrom(this.utilisateurService.getById(this.dialogRef.config.data.userId)))
	}

	codePostalChanged() {
		setTimeout(async () => {
			if (this.form.get('postalCode').valid) {
				const cp = this.form.get('postalCode').value;
				const communes = await firstValueFrom(this.geoService.getCommunesByCodePostal(cp));
				this.communes = this.helper.orderBy(communes, 'nom');

				if (this.communes.length > 0) {
					this.form.get('city').patchValue(this.communes[0].nom);
				}
			}
		}, 0)
	}

	roleChanged() {
		if (this.form.get('role').value !== ROLES.CLIENT) {
			this.form.get('regionId').patchValue(null);
			this.form.get('regionId').removeValidators(Validators.required);
		} else {
			this.form.get('regionId').addValidators(Validators.required);
		}
		this.form.get('regionId').updateValueAndValidity();
		this.form.markAllAsTouched()
	}

	async onSubmit() {
		if (this.form.valid) {
			await firstValueFrom(this.utilisateurService.updateUser(this.form.getRawValue()))
			this.close();
		}
	}

	async toggleArchived() {
		if (confirm(CONSTANTS.YOU_SURE)) {
			this.isArchived = await firstValueFrom(this.utilisateurService.toggleIsArchived(this.form.get('id').value));
		}
	}

	close() {
		this.dialogRef.config.data.saveCloseCallBack();
		this.dialogRef.close();
	}

	private initForm(user: UserDto) {
		this.form = this.formBuilder.group({
			id: [user.id],
			role: [user.role],
			lastName: [user.lastName, [Validators.required]],
			firstName: [user.firstName, [Validators.required]],
			email: [ user.email, [Validators.required, Validators.email]],
			address: [user.address, Validators.required],
			addressLineTwo: [user.addressLineTwo],
			zipCode: [user.zipCode, [Validators.required, Validators.pattern('^[0-9]{5}$'), Validators.minLength(5)]],
			city: [user.city, Validators.required],
			phone: [user.phone, [Validators.required, Validators.pattern('^[0-9]{10}$'), Validators.minLength(10)]],
			companyName: [user.companyName, Validators.required],
			function: [user.function, Validators.required],
			regionId: [user.role === ROLES.CLIENT ? user.regionalCommittees[0].id : null, Validators.required],
			billingEmail: [user.billingEmail, [Validators.email]]
		})
		this.isArchived = user.archived;
		this.form.markAsTouched();
		this.cdr.detectChanges();
	}

}
