<section>
	<div class="header">
		<h1>Historique des factures</h1>

		<button class="button is-primary" (click)="openInvoice()">
			<mat-icon>add</mat-icon>
			<span>Générer une facture</span>
		</button>
	</div>

	<table class="table is-striped is-fullwidth" *ngIf="invoices.length > 0">
		<thead>
			<tr>
				<th>Référence</th>
				<!-- <th>Date d'édition</th> -->
				<th>Date d'envoi</th>
				<th>Date limite de paiement</th>
				<th>Date de paiement</th>
				<th>Référence client</th>
				<th style="width: 100px">Montant</th>
				<th>État</th>
				<th>&nbsp;</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let invoice of invoices">
				<td><button class="button is-primary is-inverted" (click)="openInvoice(invoice)">#{{invoice.reference}}</button></td>
				<!-- <td>{{helper.dateFr(invoice.creationDate) || "indéterminé"}}</td> -->
				<td>{{!!invoice.sendDate ? helper.dateFr(invoice.sendDate) : "-"}}</td>
				<td>{{!!invoice.paymentDateLimit ? helper.dateFr(invoice.paymentDateLimit) : "-"}}</td>
				<td>{{!!invoice.paymentDate ? helper.dateFr(invoice.paymentDate) : "-"}}</td>
				<td>{{invoice.clientReference || "-"}}</td>
				<td>{{invoice.amount}}</td>
				<td>
					<div class="status" [ngClass]="invoice.status">
						{{INVOICE_STATUS_OBJ[invoice.status].label}}
					</div>
				</td>
				<td>
					<button class="button is-danger is-inverted" (click)="pdf(invoice)">
						<mat-icon>picture_as_pdf</mat-icon>
					</button>
				</td>
			</tr>
		</tbody>
	</table>

	<p *ngIf="invoices.length === 0">Aucune facture émise pour le moment.</p>

</section>
