<section class="container">
	<div class="header">
		<h1>Calendrier</h1>
		<div class="btn-group">
			<button class="button is-primary" (click)="setView(CalendarView.Month)"
				[class.is-inverted]="calendarView !== CalendarView.Month">
				Mois
			</button>
			<button class="button is-primary" (click)="setView(CalendarView.Week)"
				[class.is-inverted]="calendarView !== CalendarView.Week">
				Semaine
			</button>
			<button class="button is-primary" (click)="setView(CalendarView.Day)"
				[class.is-inverted]="calendarView !== CalendarView.Day">
				Jour
			</button>
		</div>
	</div>

	<div class="btn-group">
		<div class="button" mwlCalendarPreviousView [view]="calendarView" [(viewDate)]="viewDate" (viewDateChange)="resetCalendar()">
			<mat-icon>chevron_left</mat-icon>
			Précédent
		</div>

		<button class="button" mwlCalendarNextView [view]="calendarView" [(viewDate)]="viewDate" (viewDateChange)="resetCalendar()">
			Suivant
			<mat-icon>chevron_right</mat-icon>
		</button>
	</div>


	<div [ngSwitch]="calendarView" class="box">
		<!-- PAR MOIS -->
		<ng-container *ngSwitchCase="CalendarView.Month">
			<h4>{{helper.monthYearFr(viewDate)}}</h4>
			<mwl-calendar-month-view  #monthComponent
				[viewDate]="viewDate"
				[locale]="CONSTANTS.CALENDAR_LOCALE"
				[weekStartsOn]="CONSTANTS.CALENDAR_WEEK_STARTS_ON"
				[events]="events"
				[refresh]="refresh"
				[activeDayIsOpen]="activeDayIsOpen"
				(eventTimesChanged)="eventTimesChanged($event)"
				(beforeViewRender)="setPeriod($event)"
				(eventClicked)="eventClicked($event)">
			</mwl-calendar-month-view>
		</ng-container>

		<!-- PAR SEMAINES -->
		<mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" #weekComponent
			[viewDate]="viewDate"
			[locale]="CONSTANTS.CALENDAR_LOCALE"
			[weekStartsOn]="CONSTANTS.CALENDAR_WEEK_STARTS_ON"
			[dayStartHour]="CONSTANTS.CALENDAR_START_HOUR"
			[dayEndHour]="CONSTANTS.CALENDAR_END_HOUR"
			[hourSegments]="CONSTANTS.CALENDAR_HOUR_SEGMENTS"
			[hourSegmentHeight]="CONSTANTS.CALENDAR_SEGMENT_HEIGHT"
			[events]="events"
			[refresh]="refresh"
			[excludeDays]="[6,0]"
			(eventTimesChanged)="eventTimesChanged($event)"
			(beforeViewRender)="setPeriod($event)"
			(eventClicked)="eventClicked($event)">
		</mwl-calendar-week-view>

		<!-- PAR JOUR -->
		<ng-container *ngSwitchCase="CalendarView.Day">
			<h4>{{helper.fullDateFr(viewDate)}}</h4>
			<mwl-calendar-day-view  #dayComponent
				[viewDate]="viewDate"
				[locale]="CONSTANTS.CALENDAR_LOCALE"
				[dayStartHour]="CONSTANTS.CALENDAR_START_HOUR"
				[hourSegments]="CONSTANTS.CALENDAR_HOUR_SEGMENTS"
				[hourSegmentHeight]="CONSTANTS.CALENDAR_SEGMENT_HEIGHT"
				[events]="events"
				[refresh]="refresh"
				(eventTimesChanged)="eventTimesChanged($event)"
				(beforeViewRender)="setPeriod($event)"
				(eventClicked)="eventClicked($event)"
				>
			</mwl-calendar-day-view>
		</ng-container>
	</div>
</section>
