export const TABS = [{
	label: 'Prestations',
	route: 'prestations'
}, {
	label: 'Organiser',
	route: 'organiser'
}, {
	label: 'Devis',
	route: 'devis'
}, {
	label: 'Facturation et Paiement',
	route: 'paiement'
}, {
	label: 'Documents',
	route: 'documents'
}]

export const CLIENT_TABS = [{
	label: 'Prestations',
	route: 'prestations'
},{
	label: 'Documents',
	route: 'documents'
}]
