import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { JmaService } from 'src/app/modules/shared/services/jma.service';
import { ActivityModalComponent } from '../detail-activite/activity-modal/activity-modal.component';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { ThemeDto } from 'src/app/modules/shared/dtos/activity/theme.dto';
import { RegionalCommitteeBaseDto } from 'src/app/modules/shared/dtos/regional-comittee.dto';


@Component({
	selector: 'app-catalogue',
	templateUrl: './catalogue.component.html',
	styleUrls: ['./catalogue.component.scss'],
})

export class CatalogueComponent {

	Role = ROLES;
	themes: ThemeDto[] = [];
	currentUserRole: ROLES;
	activityId?: number;
	regionalCommitteeBaseDto: RegionalCommitteeBaseDto[] = [];
	currentCommitteeId: number;
	currentCommittee?: RegionalCommitteeBaseDto;

	constructor(
		public authService: AuthService,
		public jmaService: JmaService,
		public appService: AppService,
		public bookingService: BookingService,
		public userService: UserService,
		private dialog: MatDialog
	) { }

	addActivity() {
		this.dialog.open(ActivityModalComponent, {
			minHeight: this.appService.isMobile() ? '100vh' : 'initial',
			minWidth: this.appService.isMobile() ? '100vw' : '750px',
			data: {
				saveCloseCallback: () => this.appService.reloadPage()
			}
		});
	}
}
