import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventCalendarModalComponent } from 'src/app/modules/dashboard/modals/event-calendar-modal/event-calendar-modal.component';
import { NotificationService } from 'src/app/modules/shared/services/notification.service';


@Component({
	selector: 'app-booking-link.modal',
	templateUrl: './booking-link.modal.html',
	styleUrls: ['./booking-link.modal.scss'],
})

export class BookingLinkModal implements OnInit {

	publicLink = "";

	constructor(
		public router: Router,
		private dialog: MatDialog,
		private dialogRef: DialogRef,
		private notificationService: NotificationService
	) { }

	ngOnInit() {
		console.log(this.dialogRef.config.data)
		this.publicLink = `${window.location.origin}/reserver/${btoa(this.dialogRef.config.data.event.id.toString())}`;
	}

	copy() {
		navigator.clipboard.writeText(this.publicLink);
		this.notificationService.showSuccess('Le lien a été copier dans le presse-papier', 'Succès !')
	}

	goEvent(): void {
		this.dialogRef.close()
		this.dialog.open(EventCalendarModalComponent, {
			data: {
				eventId: this.dialogRef.config.data.event.id
			}
		})
	}
}
