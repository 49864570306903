import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { catchError, map, tap } from "rxjs";
import { UserService } from "../services/user.service";

export const accountDoesntExistsValidator = (userService: UserService): AsyncValidatorFn => {
	return (control: AbstractControl) => {
		return userService.emailAlreadyExists(control.value).pipe(
			map(response => !response.exists ? null: { exists: true, message: 'Ce compte éxiste déjà.' }),
			catchError((err) => null)
		)
	}
}
