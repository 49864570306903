import { Dialog } from '@angular/cdk/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription, firstValueFrom } from 'rxjs';
import { weekendsDatesFilter } from 'src/app/modules/shared/adapters/weekends.filter';
import { EventBaseDto } from 'src/app/modules/shared/dtos/event/event.dto';
import { ActivityService } from 'src/app/modules/shared/services/activity.service';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { EventService } from 'src/app/modules/shared/services/event.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { CONSTANTS } from 'src/constants';
import { EventCalendarModalComponent } from '../../../modals/event-calendar-modal/event-calendar-modal.component';
import { ActivityDto } from 'src/app/modules/shared/dtos/activity/activity.dto';
import { BookingDto } from 'src/app/modules/shared/dtos/booking/booking.dto';
import { BOOKING_STATUS, BOOKING_STATUS_OBJ } from 'src/app/modules/shared/enums/booking-status.enum';
import { ACTIVITY_TYPES } from 'src/app/modules/shared/enums/activity-type.enum';
import { BookingLinkModal } from './booking-link-modal/booking-link.modal';

@Component({
  selector: 'app-prestations-tab',
  templateUrl: './prestations-tab.component.html',
  styleUrl: './prestations-tab.component.scss'
})

export class PrestationsTabComponent implements OnInit, OnDestroy {

	readonly weekendsDatesFilter = weekendsDatesFilter;
	readonly ACTIVITY_TYPES = ACTIVITY_TYPES;
	readonly BOOKING_STATUS_OBJ = BOOKING_STATUS_OBJ;

	formOpen = false;
	locked = false;

	form: FormGroup;
	selectedActivity: ActivityDto;
	pokemonControl = new FormControl();
	booking: BookingDto;
	activityOptions = [];

	private subscriptions: Subscription[] = [];

	constructor(
		public helper: HelperService,
		public bookingService: BookingService,
		private activatedRoute: ActivatedRoute,
		private activityService: ActivityService,
		private formBuilder: FormBuilder,
		private router: Router,
		private eventService: EventService,
		private dialog: Dialog
	) {}

	ngOnInit() {
		this.subscriptions.push(
			this.activatedRoute.parent.data.subscribe(data => {
				this.booking = data.booking;
				this.initForm();
				this.initActivityOptions();
				this.locked = this.BOOKING_STATUS_OBJ[this.booking.status].order >= this.BOOKING_STATUS_OBJ[BOOKING_STATUS.DEVIS_SIGNE].order;
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	openBookingLinkModal(event: EventBaseDto): void {
		this.dialog.open(BookingLinkModal, { data: { event }})
	}

	showBookingButton(event: EventBaseDto): boolean {
		return BOOKING_STATUS_OBJ[this.booking.status].order >= BOOKING_STATUS_OBJ[BOOKING_STATUS.DEVIS_SIGNE].order
			&& event.activity.activityType === ACTIVITY_TYPES.INDIVIDUELLE
	}

	async getActivityOffers() {
		this.selectedActivity = await (await firstValueFrom(this.activityService.getActivityById(this.form.get("activityId").value)))
		this.form.get('offerId').patchValue(this.selectedActivity.offers[0].id);
	}

	async addEvent() {
		await firstValueFrom(this.eventService.addEventToBooking({
			...this.form.getRawValue(),
			date: moment(this.form.get('date').value).set('hours', CONSTANTS.CALENDAR_START_HOUR)
		}));

		this.refreshScreen();
		this.formOpen = false;
	}

	async deleteEvent(eventId: number) {
		if (confirm(CONSTANTS.YOU_SURE + '\nLes éventuelles réservations liées à cette activité seront supprimées.\nCette action est irréversible.')) {
			await firstValueFrom(this.eventService.removeEvent(eventId));
			this.refreshScreen();
		}
	}

	private refreshScreen(): void {
		this.router.navigateByUrl("dashboard/demandes/"+this.booking.id+'/prestations');
	}

	private initForm(): void {
		this.form = this.formBuilder.group({
			bookingId: [this.booking.id, [Validators.required]],
			activityId: [null, [Validators.required]],
			offerId: [null, [Validators.required]],
			date: [this.booking.events[0]?.date, [Validators.required]]
		})
	}

	private initActivityOptions(): void {
		this.activityOptions = this.helper.orderByString(
			this.bookingService.themes().map(t => t.activities).flat(),
			'title'
		);
		this.form.get('activityId').patchValue(this.activityOptions[0].id);
		this.getActivityOffers();
	}


}
