import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CommuneDto } from "../dtos/commune.dto";

@Injectable({
	providedIn: 'root'
})

export class GeoService {

    private headers = new HttpHeaders({'Content-Type': 'application/json'});
    private COMMUNE_FIELDS = 'nom,code,codesPostaux,codeDepartement,codeRegion,population&format=json&geometry=centre';

	constructor(private http: HttpClient) { }

	getCommunesByCodePostal(codePostal: string): Observable<CommuneDto[]> {
		return this.http.get<CommuneDto[]>(`https://geo.api.gouv.fr/communes?codePostal=${codePostal}&${this.COMMUNE_FIELDS}`, { headers: this.headers })
	}
}
