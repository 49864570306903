import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ImageUploadDto } from "../dtos/Image-upload.dto";
import { DocumentUploadDto } from "../dtos/document/document-upload.dto";
import { LinkDto } from "../dtos/link.dto";

@Injectable({
	providedIn: 'root'
})

export class UploadService {

	constructor(private http: HttpClient) { }

	uploadImage(data: ImageUploadDto): Observable<LinkDto> {
		return this.http.post<LinkDto>(`${environment.urlApi}/upload/image`, data);
	}

	uploadFile(dto: DocumentUploadDto) {
		const formData = new FormData();
    	Object.keys(dto)
			.filter(key => !!dto[key])
			.forEach(key => formData.append(key, dto[key]));
		return this.http.post(`${environment.urlApi}/upload/file`, formData)
	}

}
