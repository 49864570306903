<section *ngIf="activity">

	<div id="banner">
		<app-responsive-image
			[maxHeight]="appService.isMobile() ? '26dvh' : '78dvh'"
			[source]="activity.banner"
		></app-responsive-image>

		<div id="admin" *ngIf="userService.isFederalAdmin()">
			<button
				class="button is-info"
				[ngClass]="{'is-fullwidth' : appService.isMobile() }"
				(click)="updateActivity()"
				>
				<mat-icon style="font-size: 16px;">edit</mat-icon>
				<span>Modifier l'activité</span>
			</button>
		</div>
		<div class="mask">
			<h1>{{ activity.title ? activity.title : 'Non renseigné' }}</h1>
		</div>
	</div>

	<div id="update-info" *ngIf="userService.isFederalAdmin()">
		<span>Dernière modification le {{helper.dateFr(activity.modification)}} </span>
		<span>à {{helper.hourFr(activity.modification)}} </span>
		<span>par {{ activity.authorModification }}</span>
	</div>

	<div id="description">
		<ng-container *ngIf="!isEditingPresentation">
			<p [innerHTML]="activity.presentation"></p>

			<button
				*ngIf="userService.isFederalAdmin()"
				class="button is-info"
				[ngClass]="{'is-fullwidth' : appService.isMobile() }"
				(click)="setPresentationEdit()"
				>
				<mat-icon style="font-size: 16px;">edit</mat-icon>
				<span>Modifier la présentation</span>
			</button>
		</ng-container>

		<div class="editor" *ngIf="isEditingPresentation">
			<quill-editor
				[styles]="{height: '40vh'}"
				placeholder="Tapez votre présentation ici..."
				[(ngModel)]="activity.presentation"
			>
			</quill-editor>

			<div class="edit-buttons">
				<button
					class="button"
					[ngClass]="{'is-fullwidth' : appService.isMobile() }"
					(click)="cancelPresentationEdit()"
					>
					<mat-icon style="font-size: 16px;">close</mat-icon>
					<span>Annuler</span>
				</button>
				<button
					class="button is-primary"
					[ngClass]="{'is-fullwidth' : appService.isMobile() }"
					(click)="updateDescription()"
					>
					<mat-icon style="font-size: 16px;">save</mat-icon>
					<span>Enregistrer les modifications</span>
				</button>
			</div>
		</div>
	</div>

	<div id="price">
		<button
			*ngIf="userService.currentUser().role === ROLES.ADMIN_FEDE && activity.activityType !== ACTIVITY_TYPES.AUTRE"
			class="button is-info"
			[ngClass]="{'is-fullwidth' : appService.isMobile() }"
			(click)="openOffersModal()"
			>
			<mat-icon style="font-size: 16px;">edit</mat-icon>
			<span>Administrer les offres</span>
		</button>

		<!-- BOOK BUTTON -->
		<div id="book-area" [ngClass]="{ 'add': bookingService.activityIsSelected(activity) }" (click)="book()">
			<button class="button is-large is-primary add-to-cart" >
				<span class="btn-text">Je réserve !</span>
			</button>


		</div>

		<p class="panier">
			<ng-container *ngIf="bookingService.activityIsSelected(activity)">
				L'activité a été ajouté à votre sélection. <BR/>
				Rendez-vous dans l'onglet <a routerLink="/dashboard/nouvelle-demande">Nouvelle réservation</a> pour finaliser la demande.
			</ng-container>
		</p>
	</div>


	<div id="rc" *ngIf="userService.currentUser().role === ROLES.ADMIN_FEDE">
		<h1>Régions proposants cette activité</h1>

		<div class="columns is-multiline" >
			<div class="column is-half is-full-mobile" *ngFor="let rc of regionalCommittees">
				<mat-checkbox [(ngModel)]="rc.hasActivity" [disabled]="activity.id === 43" (ngModelChange)="toggleCommitteeActivity(rc.id)"></mat-checkbox>
				<span>{{ rc.title }}</span>
			</div>
		</div>
	</div>

</section>
