<button class="button" (click)="options.method(param)"
        [ngStyle]="{'margin': options.align ? 'auto' : 'inherit'}"
        [ngClass]="options.cssClass"
        [class.grey]="greyed"
        [class.secondary]="options.secondary"
        [disabled]="value === -1 || disabled">

    <mat-icon *ngIf="options.icon && !this.options.iconRight">{{options.icon}}</mat-icon>

    <span [ngClass]="{'right' : title && this.options.icon && !this.options.iconRight ? true : false,
                      'left' : title && this.options.icon && this.options.iconRight ? true : false}">
        <ng-container *ngIf="title != ''">{{ title | dynamicPipe:options.pipes }}</ng-container>
    </span>

    <mat-icon *ngIf="options.icon && this.options.iconRight">{{options.icon}}</mat-icon>
</button>
