<section class="container is-max-desktop">
	<div class="header">
		<h1>Mes demandes de réservation</h1>
	</div>


	<p id="no-response" *ngIf="bookingGroups?.length === 0">Aucune demande pour l'instant.</p>

	<div class="group" *ngFor="let group of bookingGroups">
		<h3>{{group.date}}</h3>
		<div class="columns is-multiline" >
			<div class="column is-4" *ngFor="let b of group.collec">
				<div class="box" (click)="router.navigate(['dashboard','demandes', b.id])">
					<div class="description">
						<b>{{helper.dateFr(b.date)}}</b>
						<div class="status" [ngClass]="b.bookingStatus">
							{{BOOKING_STATUS_OBJ[b.bookingStatus].label}}
						</div>
					</div>
					<div class="img-container">
						<img [src]="!!b.coverUrl ? b.coverUrl : 'assets/images/logo_big.jpg'" />
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
