<section class="container is-max-widescreen" *ngIf="event">

	<div class="columns is-centered" style="margin-top: 2rem">
		<div class="column is-4">

			<h1 style="text-align: center;">Réservez votre créneau</h1>

			<app-tile
				[animate]="false"
				[imageUrl]="event.activity.image"
				[title]="event.activity.title"
				[smallText]="false"
				aspectRatio="8/5"
				>
			</app-tile>

			<app-public-calendar mode="PUBLIC" [event]="event" (select)="onEventClick($event)"></app-public-calendar>
		</div>
	</div>

</section>


