import { HelperService } from './helper.service';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StatsDto } from '../dtos/stats/stats.dto';
import { StatsRequestParams } from '../models/request.params';

@Injectable({
	providedIn: 'root'
})
export class StatsService {

	constructor(
		private http: HttpClient,
		private helperService: HelperService
	) { }


	getStats(params: StatsRequestParams): Observable<StatsDto> {
		return this.http.get<StatsDto>(
			`${environment.urlApi}/stats`,
			{ params: this.helperService.getObjectAsHttpParams(params) }
		)
	}

	exportStats(params: StatsRequestParams): Observable<HttpResponse<Blob>> {
		return this.http.get(
			`${environment.urlApi}/stats/export?${this.helperService.getUrlParamsFromObject(params)}`,
			{ observe : 'response', responseType: 'blob'}
		)
	}
}
