<div id="dashboard-frame" *ngIf="!router.url.includes('reserv')">
	<app-header></app-header>
	<div id="main-frame">
		<div id="frame-container">
			<div id="scroll">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
	<app-footer></app-footer>
</div>

<router-outlet *ngIf="router.url.includes('reserv')"></router-outlet>
