export enum BOOKING_STATUS {
	NON_TRAITEE = "NON_TRAITEE",
	REFUSEE = "REFUSEE",
	DEVIS_ENVOYE = "DEVIS_ENVOYE",
	DEVIS_SIGNE = "DEVIS_SIGNE",
	PARTIELLEMENT_FACTUREE = "PARTIELLEMENT_FACTUREE",
	FACTUREE = "FACTUREE",
	PARTIELLEMENT_PAYEE = "PARTIELLEMENT_PAYEE",
	PAYEE = "PAYEE"
}

export const BOOKING_STATUS_OBJ = {
	[BOOKING_STATUS.REFUSEE]: { order: 0, label: "Refusée", disabled: false },
	[BOOKING_STATUS.NON_TRAITEE]: { order: 1, label: "En cours de traitement", disabled: false },
	[BOOKING_STATUS.DEVIS_ENVOYE]: { order: 2, label: "Devis envoyé", disabled: false },
	[BOOKING_STATUS.DEVIS_SIGNE]: { order: 3, label: "Devis signé", disabled: false },
	[BOOKING_STATUS.PARTIELLEMENT_FACTUREE]: { order: 4, label: "Partiellement facturée", disabled: true },
	[BOOKING_STATUS.FACTUREE]: { order: 5, label: "Facturée", disabled: true },
	[BOOKING_STATUS.PARTIELLEMENT_PAYEE]: { order: 6, label: "Partiellement payée", disabled: true },
	[BOOKING_STATUS.PAYEE]: { order: 7, label: "Payée", disabled: true },
}

