export enum ROLES {
	ADMIN_FEDE = "ADMIN_FEDE",
	EMPLOYE_FEDE = "EMPLOYE_FEDE",
	CLIENT = "CLIENT"
}

export const getRoleLitteral = (role: ROLES): string => {
	switch(role) {
		case ROLES.ADMIN_FEDE:
			return "Admin fédéral"
		case ROLES.EMPLOYE_FEDE:
			return "Employé fédéral"
		case ROLES.CLIENT:
			return "Client"
		default:
			return "inconnu";
	}
}
