<footer>
	<div class="foo">
		<a href="https://jmactiv-entreprise.asptt.com/" target="_blank">Accueil</a>
		<a href="https://jmactiv-entreprise.asptt.com/offres/" target="_blank">Nos offres</a>
		<a href="https://jmactiv-entreprise.asptt.com/le-journal-de-la-qvt/" target="_blank">Le journal de la QVT</a>
		<a href="https://jmactiv-entreprise.asptt.com/qui-sommes-nous/" target="_blank">Qui sommes-nous ?</a>
	</div>

	<div class="footer-info">
		<div>© {{YEAR}} Fédération sportive des ASPTT. Tous droits réservés.</div>
		<div>
			<a href="https://jmactiv-entreprise.asptt.com/credits/" target="_blank">Crédits</a> |
			<a href="https://jmactiv-entreprise.asptt.com/informations-legales/" target="_blank">Informations légales</a> |
			<a href="https://jmactiv-entreprise.asptt.com/plan-du-site/" target="_blank">Plan du site</a>
		</div>
	</div>
</footer>
