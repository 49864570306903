import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-responsive-image",
    templateUrl: "./responsive-image.component.html",
    styleUrls: ["./responsive-image.component.scss"],
})
export class ResponsiveImageComponent implements OnInit {

    @Input("source") source: string;
    @Input("maxHeight") maxHeight: string;
    @Input("minHeight") minHeight: string;

    constructor() {}

    ngOnInit(): void {}

}
