<div id="modal">
	<form *ngIf="form" [formGroup]="form" class="image-form">
		<mat-form-field appearance="fill">
			<mat-label>Titre</mat-label>
			<input matInput formControlName="title" placeholder="Titre de l'activité">
		</mat-form-field>

		<mat-form-field appearance="fill">
			<mat-label>Thème</mat-label>
			<mat-select formControlName="themeId">
				<mat-option *ngFor="let t of themes$ | async" [value]="t.id">{{t.title}}</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field appearance="fill">
			<mat-label>Type de séance</mat-label>
			<mat-select formControlName="activityType">
				<mat-option value="COLLECTIVE">Collective</mat-option>
				<mat-option value="INDIVIDUELLE">Individuelle</mat-option>
				<mat-option value="FORMATION">Formation</mat-option>
				<mat-option value="FICHE">Fiche</mat-option>
				<mat-option value="AUTRE">A la carte</mat-option>
			</mat-select>
		</mat-form-field>
	</form>

	<div id="image">
		<img [src]="form.get('image').value ? form.get('image').value : '/assets/images/placeholder.png'">
		<div>
			<input hidden #fileInput type="file" accept="image/*" (change)="openBigImageModal($event)">
			<button class="button is-info is-inverted" (click)="fileInput.click()" [ngClass]="{'is-fullwidth' : appService.isMobile() }">
				<mat-icon>image</mat-icon>
				{{ !form.get('id').value ? 'Choisir ' : 'Modifier' }} les illustrations
			</button>
		</div>
	</div>

	<div id="buttons">

		<button *ngIf="!!form.get('id').value" class="button is-danger is-inverted" (click)="deleteActivity()" [ngClass]="{'is-fullwidth' : appService.isMobile() }">
			Supprimer
		</button>

		<button class="button" (click)="dialogRef.close()" [ngClass]="{'is-fullwidth' : appService.isMobile() }">
			Fermer sans enregistrer
		</button>

		<button class="button is-primary" (click)="save()" [ngClass]="{'is-fullwidth' : appService.isMobile() }" [disabled]="!form.valid">
			Enregistrer
		</button>
	</div>
</div>
