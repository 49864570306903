import {Component, Input, OnInit} from '@angular/core';
import {LinkFunctionFieldType} from '../../models/FieldConfig';

@Component({
    selector: 'app-link-function-field',
    templateUrl: './link-function-field.component.html',
    styleUrls: ['./link-function-field.component.css']
})
export class LinkFunctionFieldComponent implements OnInit {

    @Input() value;
    @Input() options: LinkFunctionFieldType;
    @Input() param: any;
    title: string;


    constructor() {
    }

    ngOnInit() {
        this.title = this.options.label ? this.options.label : this.value;
    }

}
