import { DialogRef } from '@angular/cdk/dialog';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ActivityService } from 'src/app/modules/shared/services/activity.service';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { ImageCropperModal } from '../../../modals/image-cropper-modal/image-cropper-modal.component';
import { ImageType } from '../../../modals/image-cropper-modal/models/image-type.model';
import { CONSTANTS } from 'src/constants';
import { ActivityDto } from 'src/app/modules/shared/dtos/activity/activity.dto';


@Component({
	selector: 'app-activity-modal',
	templateUrl: './activity-modal.component.html',
	styleUrls: ['./activity-modal.component.scss'],
})
export class ActivityModalComponent implements OnInit {

	readonly defaultImageSrc: string = 'https://resources.fsasptt.com/adherons/images/others/rectangle-image.png';

	@ViewChild('fileInput') fileInput: ElementRef;

	form: FormGroup;
	imageChangedEvent: any = '';
	croppedImage: string = '';
	themes$ = this.activitesService.getThemesBasics();

	constructor(
		public dialogRef: DialogRef,
		public appService: AppService,
		private activitesService: ActivityService,
		private fb: FormBuilder,
		private dialog: MatDialog
	) { }


	async ngOnInit(): Promise<void> {
		this.initForm();
	}

	async save() {
		const req = !!this.form.get('id').value
			? this.activitesService.updateActivity(this.form.getRawValue())
			: this.activitesService.createActivity(this.form.getRawValue());

		await firstValueFrom(req);
		this.dialogRef.config.data.saveCloseCallback();
		this.dialogRef.close();
	}

	openBigImageModal(event: any): void {
		this.dialog.open(ImageCropperModal, {
			data: {
				params: {
					imageType: ImageType.ACTIVITY,
					inputImage: event,
					maintainAspectRatio: true,
					targetHeight: 600,
					targetWidth: 1200,
					modalTitle: "Grande image"
				},
				saveCloseCallback: (imageUrl: string) => {
					this.form.get('banner')?.patchValue(imageUrl)
					this.openSmallImageModal(event);
				}
			}
		})
	}

	async deleteActivity() {
		if (confirm(CONSTANTS.YOU_SURE)) {
			await firstValueFrom(this.activitesService.deleteActivity(this.form.get('id').value));
			this.dialogRef.config.data.deleteCallback();
			this.dialogRef.close();
		}
	}

	private initForm(): void {
		const activity: ActivityDto = this.dialogRef.config.data.activity;
		this.form = this.fb.group({
			themeId: [activity?.themeId, [Validators.required]],
			id: [activity?.id || null],
			title: [activity?.title || '', [Validators.required]],
			activityType: [activity?.activityType, [Validators.required]],
			image: [activity?.image || '', [Validators.required]],
			banner: [activity?.banner || this.defaultImageSrc, Validators.required],
		});
	}

	private openSmallImageModal(event: any): void {
		this.dialog.open(ImageCropperModal, {
			data: {
				params:  {
					imageType: ImageType.ACTIVITY,
					inputImage: event,
					maintainAspectRatio: true,
					targetHeight: 450,
					targetWidth: 750,
					modalTitle: "Petite image"
				},
				saveCloseCallback: (imageUrl: string) => {
					this.form.get('image')?.patchValue(imageUrl)
				}
			}
		})
	}
}
