
<div id="admin" *ngIf="userService.isFederalAdmin()">
	<button
		class="button is-info"
		[ngClass]="{'is-fullwidth' : appService.isMobile() }"
		(click)="addActivity()"
		>
		<mat-icon>add</mat-icon>
		<span>Ajouter une activité</span>
	</button>
</div>

<section class="container" *ngFor="let theme of bookingService.themes()">
	<h2>{{ theme.title }}</h2>

	<div class="columns is-centered is-multiline is-marginless">
		<div class="column is-4-desktop is-half-tablet" *ngFor="let activity of theme.activities">
			<app-tile
				[imageUrl]="activity.image"
				[title]="activity.title"
				[greyFilter]="activity.disabled"
				aspectRatio="8/5"
				[animate]="true"
				[clickable]="true"
				[routerLink]="['/dashboard/catalogue', activity.id]"
			>
			</app-tile>
		</div>
	</div>
</section>
