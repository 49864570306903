import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { CalendarDayViewComponent, CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewComponent, CalendarView, CalendarWeekViewComponent } from 'angular-calendar';
import { Observable, Subject, Subscription, firstValueFrom } from 'rxjs';
import { RegionalCommitteeBaseDto } from 'src/app/modules/shared/dtos/regional-comittee.dto';
import { EventBooking } from 'src/app/modules/shared/models/event-booking';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { EventService } from 'src/app/modules/shared/services/event.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { CONSTANTS } from 'src/constants';


@Component({
  selector: 'app-calendrier',
  templateUrl: './calendrier.component.html',
  styleUrl: './calendrier.component.scss'
})

export class CalendrierComponent implements OnInit, OnDestroy {

	readonly CONSTANTS = CONSTANTS;
	readonly CalendarView = CalendarView;

    @ViewChild('monthComponent') monthComponent: CalendarMonthViewComponent;
    @ViewChild('weekComponent') weekComponent: CalendarWeekViewComponent;
    @ViewChild('dayComponent') dayComponent: CalendarDayViewComponent;

    calendarView: CalendarView = CalendarView.Week;
    viewDate: Date = new Date();
    refresh: Subject<any> = new Subject();
	events: CalendarEvent[] = [];
	activeDayIsOpen = true;
	currentPeriod: { start: Date, end: Date };
	dataLoaded = false;
	currentRegionId: number;
	serverEvents: EventBooking[] = [];
	dayEvent: CalendarEvent;
	regionIsSet = false;

	private subscriptions: Subscription[] = [];
	private currentRegion$: Observable<RegionalCommitteeBaseDto>;

	constructor(
		public helper: HelperService,
		private bookingService: BookingService,
		private userService: UserService,
		private router: Router,
		private eventService: EventService
	) {
		this.currentRegion$ = toObservable(this.userService.currentRegion);
	}

	ngOnInit(): void {
		this.watchRegionChanged();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	setView(view: CalendarView) {
        this.calendarView = view;
		this.dataLoaded = false;
	}

	resetCalendar() {
		this.events = [];
		this.dataLoaded = false;
	}

	setPeriod(event: { period: {start: Date, end: Date} }): void {
		this.currentPeriod = event.period;
		if (!this.dataLoaded) {
			this.loadEvents();
		}
	}

	eventTimesChanged(calendarEvent: CalendarEventTimesChangedEvent) {
		this.updateCalendar(calendarEvent);
		this.updateOnServer(calendarEvent);
	}

	eventClicked(evt: any) {
		const correspondingEvent = this.serverEvents.find(e => e.id === evt.event.id);
		if (confirm(CONSTANTS.SWITCH_BOOKING)) {
			this.router.navigateByUrl('dashboard/demandes/'+correspondingEvent.bookingId);
		}
	}

	async loadEvents() {
		if (this.regionIsSet) {
			this.dataLoaded = true;
			this.serverEvents = (await firstValueFrom(
				this.eventService.getEventsByPeriod(
					this.currentPeriod.start,
					this.currentPeriod.end,
					this.userService.currentRegion()?.id
				)
			))
			this.events = this.serverEvents.map(event => event.calendarEvent);
		}
	}

	private watchRegionChanged(): void {
		this.subscriptions.push(
			this.currentRegion$.pipe().subscribe((region) => {
				this.regionIsSet = true;
				this.resetCalendar();
				this.loadEvents();
			})
		)
	}

	private async updateCalendar(calendarEvent: CalendarEventTimesChangedEvent) {
		const eventId = calendarEvent.event.id;
		const correspondingEvent = this.events.find(e => e.id === eventId);
		correspondingEvent.start = calendarEvent.newStart;
		correspondingEvent.end = calendarEvent.newEnd;
		this.refresh.next(null);
	}

	private async updateOnServer(calendarEvent: CalendarEventTimesChangedEvent) {
		const eventId = calendarEvent.event.id;
		const correspondingEvent = this.serverEvents.find(se => se.id === eventId);
		correspondingEvent.date = calendarEvent.newStart;
		await firstValueFrom(this.eventService.updateEvent(correspondingEvent.dto));
	}

}
