import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: 'root',
})

export class DocumentService {

	constructor(
		private http: HttpClient
	) {}

	downloadDocument(documentUrl) {
		return this.http.get(documentUrl, { observe : 'response', responseType: 'blob' })
	}

	getDocumentsByBooking(bookingId: number) {
		return this.http.get(`${environment.urlApi}/document/by-booking/${bookingId}`);
	}

	deleteDocument(documentId: number) {
		return this.http.delete(`${environment.urlApi}/document/${documentId}`)
	}
}
