<div class="box" *ngIf="mainEvent && events">

	<h4>{{helper.fullDateFr(viewDate)}}</h4>

	<mwl-calendar-day-view  #dayComponent
		[viewDate]="viewDate"
		[locale]="CONSTANTS.CALENDAR_LOCALE"
		[dayStartHour]="dayStartHour"
		[dayEndHour]="dayEndHour"
		[hourSegments]="hourSegments"
		[hourSegmentHeight]="36"
		[events]="events"
		[refresh]="refresh"
		(eventTimesChanged)="eventTimesChanged($event)"
		(beforeViewRender)="setPeriod($event)"
		(eventClicked)="eventClicked($event)">
	</mwl-calendar-day-view>
</div>
