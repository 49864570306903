import { Injectable } from '@angular/core';
import { CalendarDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import * as moment from 'moment';
import { HelperService } from '../services/helper.service';


@Injectable()
export class JmaCalendarDateAdapter extends CalendarDateFormatter {

	constructor(
		dateAdapter: DateAdapter,
		private helperService: HelperService
	) {
		super(dateAdapter)
	}

	private getWeekDay(date): string {
		moment.locale('fr-FR')
		return this.helperService.capitalize(moment(date).format("dddd"));
	}

	private getViewHour(date): string {
		moment.locale('fr-FR')
		return this.helperService.capitalize(moment(date).format("HH[h]mm"));
	}

	/* MONTH VIEW */
	override monthViewTitle(params: DateFormatterParams): string {
		moment.locale('fr-FR')
		return this.helperService.capitalize(moment(params.date).format("MMMM YYYY"));
	}

	override monthViewColumnHeader(params: DateFormatterParams): string {
		return this.getWeekDay(params.date);
	}

	/* WEEK VIEW */
	override weekViewColumnHeader(params: DateFormatterParams): string {
		return this.getWeekDay(params.date);
	}

	override weekViewHour(params: DateFormatterParams): string {
		return this.getViewHour(params.date);
	}

	/* DAY VIEW */
	override dayViewHour(params: DateFormatterParams): string {
		return this.getViewHour(params.date);
	}

	override dayViewTitle(params: DateFormatterParams): string {
		return this.helperService.capitalize(moment(params.date).format('dddd D MMM  YYYY'));
	}
}
