import { HttpErrorResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})


export class NotificationService {

    constructor(private toastr: ToastrService) {
    }

    showSuccess(message, title) {
        this.toastr.success(message, title);
    }

    showError(message, title) {
        this.toastr.error(message, title);
    }

    showInfo(message, title) {
        this.toastr.info(message, title, {
            timeOut: 10000,
            tapToDismiss: true
        });
    }

    showFormError(error: HttpErrorResponse) {
        this.toastr.error(error.message, 'Erreur', {
            timeOut: 6000,
            tapToDismiss: true
        });
    }
}
