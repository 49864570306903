<h1>Changement de mot de passe</h1>

<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">

	<mat-form-field appearance="fill">
		<mat-label>Nouveau mot de passe</mat-label>
		<input matInput formControlName="password" type="password">
		<mat-error *ngIf="form.controls['password'].hasError('matching')">
			Les mots de passe ne correspondent pas.
		</mat-error>
	</mat-form-field>

	<mat-form-field appearance="fill">
		<mat-label>Retapez vôtre nouveau mot de passe</mat-label>
		<input matInput formControlName="passwordTwo" type="password">
		<mat-error *ngIf="form.controls['passwordTwo'].hasError('matching')">
			Les mots de passe ne correspondent pas
		</mat-error>
	</mat-form-field>

	<button class="button is-primary is-fullwidth" [disabled]="!form.valid" type="submit">
		Modifier mon mot de passe
	</button>
</form>
