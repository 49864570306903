<div [ngStyle]="{'width': options.width,
				'height': options.height ? options.height : 'auto',
				'margin': options.align ? 'auto' : 'inherit' }">

	<img [src]="value"
		 loading="lazy"
		 [mtxTooltip]="tooltipTpl"
		 mtxTooltipPosition="right"
	/>

	<!-- TOOLTIP -->
	<ng-template #tooltipTpl>
		<img [src]="value">
	</ng-template>

</div>

