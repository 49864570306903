
<div id="request-recovery">

	<h1>Demande changement de mot de passe</h1>

	<mat-form-field>
		<mat-label>Entrer votre email</mat-label>
		<input matInput [formControl]="email">
		<mat-error *ngIf="email.hasError('exists')">Ce compte n'existe pas.</mat-error>
	</mat-form-field>

	<p>Un email contenant un lien de changement de mot de passe vous sera envoyé.</p>


	<div class="buttons">
		<button class="button" (click)="dialogRef.close()">Annuler</button>
		<button class="button is-primary" [disabled]="!email.valid" (click)="resetPassword()">Envoyer</button>
	</div>
</div>
