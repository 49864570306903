<section class="container is-max-desktop">
	<div class="bloc">
		<h1>
			<svg class="icon-loader-check"
				x="0px" y="0px" width="471.197px" height="471.197px" viewBox="0 0 510 510" overflow="inherit" preserveAspectRatio="xMidYMid meet">
				<g id="loader">
				<circle class="circle" fill="transparent" stroke="#41BD59" stroke-width="48" stroke-linecap="round" stroke-miterlimit="10" cx="250" cy="250" r="212.599"/>
				<polyline class="check" fill="none" stroke="#41BD59" stroke-width="32" stroke-linecap="round" stroke-linejoin="round" points="227.599,322.099 290.599,259.099 180.599,149.099 "/>
				</g>
			</svg>
			<span>Demande de réservation reçue !</span>
		</h1>
		<p style="margin-top: 1rem; margin-bottom: 2rem;">Nous mettons tout en oeuvre pour y répondre dans les meilleurs délais.</p>
		<p>Merci pour votre confiance,<BR>
		L'équipe J'MACTIV Entreprise</p>
	</div>
</section>

