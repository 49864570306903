<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
        [matMenuTriggerData]="{id: value}">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-id="id">
        <button mat-menu-item *ngFor="let btn of options.buttons" (click)="btn.method(id)">
            <mat-icon *ngIf="btn.icon !== ''">{{btn.icon}}</mat-icon>
            <span>{{btn.label}}</span>
        </button>
    </ng-template>

</mat-menu>
