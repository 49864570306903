<div id="inscription">
	<div id="update-pw" *ngIf="MODE === 'UPDATE'">
		<a [routerLink]="['/dashboard/compte/update-pw']">Modifier mon mot de passe</a>
	</div>

	<h1 *ngIf="MODE === 'CREATE'">Bienvenue sur J'MACTIV Entreprise !</h1>
	<h1 *ngIf="MODE === 'UPDATE'">Mes informations personnelles</h1>

	<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="columns is-marginless">
			<div class="column is-half">
				<mat-form-field appearance="fill">
					<mat-label>Nom de l'entreprise</mat-label>
					<input matInput formControlName="companyName" />
				</mat-form-field>
			</div>
			<div class="column is-half">
				<mat-form-field appearance="fill">
					<mat-label>Votre fonction dans l'entreprise</mat-label>
					<mat-select formControlName="function">
						<mat-option *ngFor="let job of JOBS" [value]="job">
							{{job}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="columns is-marginless">
			<div class="column is-half">
				<mat-form-field appearance="fill">
					<mat-label>Votre nom</mat-label>
					<input matInput formControlName="lastName" #lastname (input)="form.get('lastName').patchValue(form.get('lastName').value.toUpperCase())" />
					<mat-error *ngIf="form.controls['lastName'].hasError('required')">
						Le champ est requis.
					</mat-error>
				</mat-form-field>
			</div>
			<div class="column is-half">
				<mat-form-field appearance="fill">
					<mat-label>Votre prénom</mat-label>
					<input matInput formControlName="firstName" />
					<mat-error *ngIf="form.controls['firstName'].hasError('required')">
						Le champ est requis.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="columns is-marginless">
			<div class="column is-half">
				<mat-form-field appearance="fill">
					<mat-label>Votre Email (identifiant)</mat-label>
					<input matInput formControlName="email" type="email"/>
					<mat-error *ngIf="form.controls['email'].hasError('exists')">
						Ce compte existe déjà.
						<a (click)="forgottenPassword()">Mot de passe oublié ?</a>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="column is-half">
				<mat-form-field appearance="fill">
					<mat-label>Votre numéro de téléphone</mat-label>
					<input matInput formControlName="phone" maxlength="14" mask="00.00.00.00.00" />
					<mat-error *ngIf="form.controls['phone'].hasError('required')">
						Le champ est requis.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="columns is-marginless" *ngIf="MODE === 'CREATE'">
			<div class="column is-half">
				<mat-form-field appearance="fill">
					<mat-label>Mot de Passe</mat-label>
					<input matInput formControlName="password" type="password" />
					<mat-error *ngIf="form.controls['password'].hasError('required')">
						Le champ est requis.
					</mat-error>
				</mat-form-field>
			</div>

			<div class="column is-half">
				<mat-form-field appearance="fill">
					<mat-label>Retapez votre mot de Passe</mat-label>
					<input matInput formControlName="passwordTwo" type="password" />
					<mat-error *ngIf="form.controls['passwordTwo'].hasError('matching')">
						Les mots de passe ne correspondent pas.
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div class="columns is-marginless">
			<div class="column is-half">
				<mat-form-field appearance="fill">
					<mat-label>Adresse de l'entreprise</mat-label>
					<input matInput formControlName="address" />
				</mat-form-field>
			</div>
			<div class="column is-half">
				<mat-form-field appearance="fill">
					<mat-label>Complément d'adresse</mat-label>
					<input matInput formControlName="addressLineTwo" />
				</mat-form-field>
			</div>
		</div>

		<div class="columns is-marginless">
			<div class="column is-half" *ngIf="MODE === 'CREATE'">
				<mat-form-field appearance="fill">
					<mat-label>Région de l'entreprise</mat-label>
					<mat-select formControlName="regionId">
						<mat-option *ngFor="let comite of comiteRegionals" [value]="comite.id">
							{{comite.title }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="column is-2">
				<mat-form-field appearance="fill">
					<mat-label>Code postal</mat-label>
					<input matInput formControlName="zipCode" mask="00000" (keydown)="codePostalChanged()" />
					<mat-error *ngIf="form.controls['zipCode'].hasError('required')">
						Requis.
					</mat-error>
				</mat-form-field>
			</div>
			<div class="column is-4">
				<mat-form-field appearance="fill">
					<mat-label>Ville de l'entreprise</mat-label>
					<input matInput type="text" required formControlName="city" [matAutocomplete]="citySuggestions" #cityField>
					<mat-autocomplete #citySuggestions>
						<mat-option *ngFor="let option of communes" [value]="option.nom">{{ option.nom }}</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
		</div>


		<button class="button is-primary is-large" type="submit" [disabled]="!form.valid">
			<span *ngIf="MODE === 'CREATE'">Créer mon compte</span>
			<span *ngIf="MODE === 'UPDATE'">Mettre à jour</span>
		</button>
	</form>
</div>
