import { Component, OnDestroy, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as moment from 'moment';
import { Observable, Subscription, debounceTime, filter, firstValueFrom, map } from 'rxjs';
import { weekendsDatesFilter } from 'src/app/modules/shared/adapters/weekends.filter';
import { ActivityMinimalDto } from 'src/app/modules/shared/dtos/activity/activity.dto';
import { BookingEventDto } from 'src/app/modules/shared/dtos/booking/booking-event.dto';
import { RegionalCommitteeBaseDto } from 'src/app/modules/shared/dtos/regional-comittee.dto';
import { UserDto } from 'src/app/modules/shared/dtos/user/user.dto';
import { UserMinimalDto } from 'src/app/modules/shared/dtos/user/user.minimal.dto';
import { ACTIVITY_TYPES } from 'src/app/modules/shared/enums/activity-type.enum';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { ComparatorService } from 'src/app/modules/shared/services/comparator.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { UserService } from 'src/app/modules/shared/services/user.service';



@Component({
	selector: 'app-nouvelle-reservation',
	templateUrl: './nouvelle-reservation.component.html',
	styleUrls: ['./nouvelle-reservation.component.scss']
})


export class NouvelleReservationComponent implements OnInit, OnDestroy {

	readonly ACTIVITY_TYPES = ACTIVITY_TYPES;
	readonly weekendsDatesFilter = weekendsDatesFilter;
	readonly ROLES = ROLES;

	form: FormGroup;
	localActivities: ActivityMinimalDto[] = [];
	selectedActivity?: ActivityMinimalDto;
	customers: UserDto[] = [];
	filteredCustomers: UserDto[] = [];
	currentRegion$: Observable<RegionalCommitteeBaseDto>;

	private subscriptions: Subscription[] = [];

	constructor(
		public appService: AppService,
		public bookingService: BookingService,
		public authService: AuthService,
		public helperService: HelperService,
		public userService: UserService,
		private formBuilder: FormBuilder,
		private comparatorService: ComparatorService
	) {}

	async ngOnInit() {
		if (this.userService.currentUser().role !== ROLES.CLIENT) {
			await this.loadCustomers();
			this.initCustomerForm();
			this.watchSearchCustomers();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	clientDisplayName(c: UserDto): string {
		return !!c ? `${c.companyName} - ${c.firstName} ${c.lastName} - ${c.email}` : "";
	}

	isNextAvailableDate(date): boolean {
		return moment(date).diff(moment(this.bookingService.nextAvailableDate)) === 0;
	}

	duplicate(bookingEvent: BookingEventDto) {
		this.bookingService.booking().push({...bookingEvent});
	}

	updateCustomer(event: MatAutocompleteSelectedEvent) {
		this.bookingService.initMessage(this.customers.find(c => c.id === event.option.value.id));
		this.bookingService.customerId.set(event.option.value.id);
	}

	regionChanged(): void {
		if (this.form.get('regionId').value !== 0) {
			this.bookingService.customerId.set(null);
			this.form.get('customerSearch').patchValue(null);
		}
		this.loadCustomers();
	}

	private async loadCustomers() {
		const regionId = this.form?.get('regionId').value !== 0 ? this.form?.get('regionId').value : null;
		this.customers = (await firstValueFrom(this.userService.getMyCustomers(regionId)));
		this.filteredCustomers = this.customers;
	}

	private initCustomerForm(): void {
		this.form = this.formBuilder.group({
			regionId: [0],
			customerSearch: [this.customers.find(c => c.id === this.bookingService.customerId())],
		})
		this.watchSearchCustomers();
	}

	private watchSearchCustomers(): void {
        this.form.get('customerSearch').valueChanges.pipe(
			filter(value => value.length > 0),
			debounceTime(700),
			map((value: string) => {
				return this.customers.filter(c => {
					const cS = this.comparatorService.simplify(c.companyName + c.firstName + c.lastName)
					const val = this.comparatorService.simplify(value);
					return cS.includes(val)
				})
			})
		).subscribe(result => this.filteredCustomers = result);
    }

}
