import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ComparatorService {

	constructor() {}

	getFilteredOptions(input: string, options: string[]): string[] {
		return options.filter((o) => this.simplify(o).includes(this.simplify(input)));
	}

	simplify(input: string): string {
		return this.normalize(input.toLowerCase())
	}

	private normalize(input: string): string {
		return input.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '') 	// Remove accents
					.replace(/\s+/g, '') 				// removes spaces
	}
}
