export enum INVOICE_STATUS {
	NON_ENVOYEE = "NON_ENVOYEE",
	ENVOYEE = "ENVOYEE",
	PAYEE = "PAYEE",
	PREMIERE_RELANCE = "PREMIERE_RELANCE",
	DEUXIEME_RELANCE = "DEUXIEME_RELANCE"
}

export const INVOICE_STATUS_OBJ = {
	[INVOICE_STATUS.NON_ENVOYEE]: { order: 0, label: "Non envoyée" },
	[INVOICE_STATUS.ENVOYEE]: { order: 1, label: "Envoyée" },
	[INVOICE_STATUS.PAYEE]: { order: 2, label: "Payée" },
	[INVOICE_STATUS.PREMIERE_RELANCE]: { order: 3, label: "Première relance" },
	[INVOICE_STATUS.DEUXIEME_RELANCE]: { order: 4, label: "Deuxième relance" },
}

