import {Component, Input, OnInit} from '@angular/core';
import {MenuFieldType} from '../../models/FieldConfig';

@Component({
    selector: 'app-menu-field',
    templateUrl: './menu-field.component.html',
    styleUrls: ['./menu-field.component.css']
})
export class MenuFieldComponent implements OnInit {
    @Input() value;
    @Input() options: MenuFieldType;

    constructor() {
    }

    ngOnInit() {
    }

}
