import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
	selector: 'app-booking-success-modal',
	templateUrl: './booking-success-modal.component.html',
	styleUrls: ['./booking-success-modal.component.scss'],
})
export class BookingSuccessModalComponent implements OnInit {

	constructor(
		public router: Router,
		private dialogRef: DialogRef
	) {}

	async ngOnInit(): Promise<void> {}

	go(route: string) {
		this.router.navigate(['dashboard', route]);
		this.dialogRef.close();
	}
}
