import { CommonModule, CurrencyPipe, DatePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { DynamicFieldDirective } from './dynamic-field/dynamic-field.directive';
import { ButtonFieldComponent } from './fields/button-field/button-field.component';
import { CheckboxFieldComponent } from './fields/checkbox-field/checkbox-field.component';
import { ImageFieldComponent } from './fields/image-field/image-field.component';
import { LinkFieldComponent } from './fields/link-field/link-field.component';
import { LinkFunctionFieldComponent } from './fields/link-function-field/link-function-field.component';
import { ListFieldComponent } from './fields/list-field/list-field.component';
import { MenuFieldComponent } from './fields/menu-field/menu-field.component';
import { RadioFieldComponent } from './fields/radio-field/radio-field.component';
import { TextFieldComponent } from './fields/text-field/text-field.component';
import { DynamicPipePipe } from './pipe/dynamic-pipe.pipe';
import { TableCreatorComponent } from './table-creator/table-creator.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BooleanPipe } from './pipe/boolean.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    declarations: [
        TableCreatorComponent,
        TextFieldComponent,
        LinkFieldComponent,
        ButtonFieldComponent,
        DynamicFieldDirective,
        DynamicPipePipe,
        MenuFieldComponent,
        LinkFunctionFieldComponent,
        ListFieldComponent,
        ImageFieldComponent,
        CheckboxFieldComponent,
		RadioFieldComponent,
    ],
    exports: [
        TableCreatorComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        RouterModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
		MtxTooltipModule,
		DragDropModule,
		MatChipsModule
    ],
    providers: [
        CurrencyPipe,
        DatePipe,
        TitleCasePipe,
		UpperCasePipe,
		BooleanPipe
    ]
})
export class DynamicTableModule {
}
