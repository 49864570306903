import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import * as moment from 'moment';
import { Observable, combineLatest, filter, firstValueFrom, switchMap, tap } from "rxjs";
import { CONSTANTS } from "src/constants";
import { environment } from "src/environments/environment";
import { CreateBookingDto } from "../dtos/booking/create-booking.dto";
import { ROLES } from "../enums/roles.enum";
import { BookingEventDto } from "../dtos/booking/booking-event.dto";
import { BOOKING_STATUS } from "../enums/booking-status.enum";
import { PageResponse } from '../dtos/page-response.dto';
import { ThemeDto } from "../dtos/activity/theme.dto";
import { BookingMinimalDto } from "../dtos/booking/booking-minimal.dto";
import { CreateLegacyProposalDto } from "../dtos/legacy-proposal/legacy-proposal.dto";
import { BookingRequestParams } from "../models/request.params";
import { ActivityService } from "./activity.service";
import { HelperService } from './helper.service';
import { UserService } from "./user.service";
import { UserDto } from "../dtos/user/user.dto";
import { ActivityDto } from "../dtos/activity/activity.dto";
import { BookingDto } from "../dtos/booking/booking.dto";



@Injectable({
	providedIn: 'root',
})

export class BookingService {

	themes = toSignal(
		combineLatest([
			toObservable(this.userService.currentRegion),
			toObservable(this.userService.currentUser)
		])
		.pipe(
			filter(([currentRegion, currentUser]) => {
				if (!localStorage.getItem('authToken')) {
					return false;
				}

				if (currentUser?.role !== ROLES.ADMIN_FEDE) {
					return !!currentRegion;
				}
				return !!currentUser;
			}),
			switchMap(([currentRegion, currentUser]) => {
				return this.activityService.getThemes(currentRegion?.id).pipe(
					tap(themes => {
						if (environment.fillClientCart) {
							this.fillClientCart(themes)
						}
					})
				)
			})
		)
	)


	nextAvailableDate: Date = new Date();
	booking = signal<BookingEventDto[]>([]);
	message: string = "";
	customerId = signal<number>(0);

	constructor(
		private activityService: ActivityService,
		private http: HttpClient,
		private helperService: HelperService,
		private router: Router,
		private userService: UserService
	) {
		this.setNextAvailableDate();
	}

	/**
	 * FOR DEVELOPMENT PURPOSE
	 **/
	async fillClientCart(themes: ThemeDto[]) {
		this.booking.set([]);
		for (var i = 0; i < 3; i++) {
			const randomThemeId = Math.floor(Math.random() * 3);
			const randomActivityId = Math.floor(Math.random() * 6);
			const activityId = themes[randomThemeId].activities[randomActivityId].id;
			const activity = await firstValueFrom(this.activityService.getActivityById(activityId));

			this.booking().push({
				date: this.nextAvailableDate,
				activity,
				offer: activity.offers[0],
				salle: ""
			})
		}
	}

	activityIsSelected(activity: ActivityDto): boolean {
		return !!this.booking().find(e => e.activity.id === activity.id);
	}

	refreshThemes(): void {
		const currentRegionBck = this.userService.currentRegion();
		this.userService.currentRegion.set(undefined);
		this.userService.currentRegion.set(currentRegionBck);
	}

	async submitDemand()  {
		await firstValueFrom(this.http.post<any>(`${environment.urlApi}/booking`, this.getCreateBookingDto()))
		this.booking.set([]);
		this.initMessage(this.userService.currentUser());
		this.router.navigateByUrl('/dashboard/nouvelle-demande/succes');
	}

	getBookings(params: BookingRequestParams): Observable<PageResponse<BookingMinimalDto>> {
		return this.http.get<PageResponse<BookingMinimalDto>>(
			`${environment.urlApi}/booking/by-filters`,
			{ params: this.helperService.getObjectAsHttpParams(params) }
		)
	}

	getBooking(bookingId: number): Observable<BookingDto> {
		return this.http.get<BookingDto>(`${environment.urlApi}/booking/${bookingId}`)
	}

	initMessage(user: UserDto): void {
		this.message = "Bonjour,\n" +
			"Je souhaiterais que la prestation se tienne à l'adresse suivante:\n"+
			user.address +"\n"+
			user.zipCode +" "+ user.city +"\n\n"+
			"N'hésitez pas à me recontacter au "+ this.helperService.formatPhoneNumber(user.phone) +"\n"+
			"Ou par email à "+user.email +"\n\n"+
			"Merci,\n"+
			user.firstName +" "+ user.lastName;
	}

	reset(bookingId: number) {
		return this.http.post(`${environment.urlApi}/booking/${bookingId}/reset`, null);
	}

	updateBookingStatus(newStatus: BOOKING_STATUS, bookingId: number) {
		return this.http.put(`${environment.urlApi}/booking/${bookingId}/update-status`, {
			status: newStatus,
			bookingId
		})
	}

	createLegacyProposal(dto: CreateLegacyProposalDto) {
		return this.http.post(`${environment.urlApi}/legacy`, dto);
	}

	private getCreateBookingDto(): CreateBookingDto {
		return {
			message: this.message,
			userId: this.customerId(),
			createEventDtos: this.booking().map(event => {
				return {
					activityId: event.activity.id,
					offerId: event.offer.id,
					date: moment(event.date).utc(true).toDate()
				}
			})
		};
	}

	private setNextAvailableDate(): void {
		this.nextAvailableDate = this.changeDate(new Date(), 'FORWARD', CONSTANTS.BOOKING_MIN_DELAY);
		this.nextAvailableDate.setHours(CONSTANTS.CALENDAR_START_HOUR);
		this.nextAvailableDate.setMinutes(0);
		this.nextAvailableDate.setSeconds(0);
		this.nextAvailableDate.setMilliseconds(0)
	}

	private changeDate(date: Date, direction: 'FORWARD' | 'BACKWARD', nbDays: number): Date {
		const dateAsMoment = moment(date)
		dateAsMoment.add(direction === 'FORWARD' ? nbDays : -nbDays, 'days');

		/** SKIP WEEKENDS */
		if (dateAsMoment.isoWeekday() > 5) {				// 1= Monday, 3= Wednesday, 6= Saturday...
			if (direction === 'FORWARD') {
				dateAsMoment.add(1, 'weeks').isoWeekday(1); // Setting next Monday
			} else {
				dateAsMoment.isoWeekday(5); 				// Setting next Friday
			}
		}
		return dateAsMoment.toDate();
	}
}
