export interface PageResponse<T> {
	content: T[];
	empty: boolean;
	first: boolean;
	last: boolean;
	number: number;
	numberOfElements: number;
	pageable: any;
	size: number;
	sort: any;
	totalElements: number;
	totalPages: number;
}

export const EmptyPageResponse: PageResponse<any> = {
	content: [],
	empty: false,
	first: false,
	last: false,
	number: 0,
	numberOfElements: 0,
	pageable: undefined,
	size: 0,
	sort: undefined,
	totalElements: 0,
	totalPages: 0
}
