import {Component, Input, OnInit} from '@angular/core';
import {TextFieldType} from '../../models/FieldConfig';
import {ThemePalette} from '@angular/material/core';

export interface ChipColor {
    id: number;
    color: ThemePalette;
}

@Component({
    selector: 'app-text-field',
    templateUrl: './text-field.component.html',
    styleUrls: ['./text-field.component.css']
})
export class TextFieldComponent implements OnInit {

    @Input() value;
    @Input() options: TextFieldType;
    availableColors: ChipColor[] = [
        {id: 0, color: undefined},
        {id: 1, color: 'primary'},
        {id: 2, color: 'accent'},
        {id: 3, color: 'warn'}
    ];

    constructor() {
    }

    ngOnInit() {
    }

    getColor(id: number): string {
        return this.availableColors.find(status => status.id === id).color;
    }

}
