<h1>Connexion</h1>

<form [formGroup]="loginForm" *ngIf="loginForm">
	<mat-form-field appearance="fill">
		<mat-label>Email</mat-label>
		<input matInput formControlName="email" type="email" />
		<mat-error *ngIf="loginForm.controls['email'].hasError('doesNotExist')">
			L'adresse e-mail n'existe pas!
		</mat-error>
	</mat-form-field>

	<mat-form-field appearance="fill">
		<mat-label>Mot de passe</mat-label>
		<input matInput formControlName="password" type="password">
		<mat-error *ngIf="loginForm.controls.password.hasError('required')">
			Mot de passe est obligatoire
		</mat-error>
	</mat-form-field>

	<button class="button is-primary is-large" (click)="submit()" [disabled]="!loginForm.valid">
		Connexion
	</button>

	<div class="reset-password">
		<a (click)="forgottenPassword()">Mot de passe oublié?</a>
	</div>
</form>

