
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, catchError, firstValueFrom, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { BookingService } from './booking.service';
import { ROLES } from '../enums/roles.enum';
import { PasswordChangeDto } from '../dtos/user/password-change.dto';
import { UserDto } from '../dtos/user/user.dto';
import { CONSTANTS } from 'src/constants';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})

export class AuthService {

	attemptedConnection = signal<boolean>(false);

	constructor(
		private http: HttpClient,
		private router: Router,
		private userService: UserService,
		private bookingService: BookingService
	) { }

	async testLogin(redirect?: boolean) {
		this.attemptedConnection.set(true);

		if (!!localStorage.getItem('authToken')) {
			try {
				const user = await firstValueFrom(this.authenticateByToken());
				this.setCurrentUser(user);
				console.log('@Connected as', user.firstName + " " + user.lastName)

				if (redirect || location.pathname == "/") {
					const params = {
						startAt: new Date().getFullYear().toString() + '-01-01',
						endAt: moment().format(CONSTANTS.DATE_TRANSACTION_FORMAT)
					}
					this.router.navigate(
						user.role === ROLES.CLIENT
							? ['dashboard']
							: ['dashboard', 'statistiques'] , { queryParams : params }
					);
				}
			} catch (e) {
				this.logout();
			}
		}
	}

	authenticateAndGetToken(email: string, password: string): Observable<{ token: string; success: boolean }> {
		return this.http
			.post<{ token: string; success: boolean }>(
				`${environment.urlApi}/public/authenticate`,
				{ email, password }
			)
			.pipe(
				tap((result) => this.saveToken(result.token)),
				catchError(this.handleError)
			);
	}

	getToken(): string {
		return localStorage.getItem('authToken') || '';
	}

	requestPasswordReset(email: string): Observable<any> {
		return this.http.post(
			`${environment.urlApi}/public/request-password-reset`,
			{ email },
			{ responseType: 'text' as 'json' }
		);
	}

	resetPassword(dto: PasswordChangeDto): Observable<any> {
		return this.http.post<{ token: string; success: boolean }>(
			`${environment.urlApi}/public/reset-password`,
			dto
		).pipe(
			tap((result) => this.saveToken(result.token)),
			catchError(this.handleError)
		)
	}

	validateEmailToken(token: string) {
		return this.http.post<{ valid: boolean }>(
			`${environment.urlApi}/public/validate/email-token/${token}`,
			null,
		)
	}

	logout() {
		localStorage.removeItem('authToken');
		this.userService.currentUser.set(null);
		this.userService.currentRegion.set(null);
		this.bookingService.booking.set([]);
		this.attemptedConnection.set(false);
		this.router.navigate(['/connexion']);
	}

	setCurrentUser(user: UserDto) {
		this.userService.currentUser.set(user);

		if (user.role === ROLES.CLIENT) {
			this.bookingService.customerId.set(user.id);
			this.bookingService.initMessage(user);
		}

		if (user.regionalCommittees.length === 1) {
			this.userService.currentRegion.set(user.regionalCommittees[0])
		}
	}

	private saveToken(token: string) {
		localStorage.setItem('authToken', token);
		this.testLogin(true);
	}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			console.error('Erreur Client :', error.error.message);
		} else {
			console.error(
				`Server returned code ${error.status}, ` +
				`Le body était : ${error.error}`
			);
		}
		return throwError("Quelque chose s'est mal passé avec l'authentification.");
	}

	private authenticateByToken(): Observable<UserDto> {
		return this.http.get<UserDto>(`${environment.urlApi}/user`)
	}
}
