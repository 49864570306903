import { Injectable } from "@angular/core";
import { RegionalCommitteeHavingActivityDto } from "../dtos/regional-comittee.dto";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})

export class RegionalCommitteeService {

	constructor(
		private http: HttpClient
	) {}

	getRegionalCommitteesHavingActivityId(activityId: number): Observable<RegionalCommitteeHavingActivityDto[]> {
		return this.http.get<RegionalCommitteeHavingActivityDto[]>(
			`${environment.urlApi}/regional-committee/having-activity/${activityId}`
		);
	}

	toggleRegionalCommitteeActivity(rcId: number, activityId: number): Observable<RegionalCommitteeHavingActivityDto[]> {
		return this.http.post<RegionalCommitteeHavingActivityDto[]>(
			`${environment.urlApi}/regional-committee/${rcId}/toggle-activity/${activityId}`,
			null
		);

	}

}
