import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { InvoiceRequestParams } from "../models/request.params";
import { HelperService } from "./helper.service";
import { PageResponse } from "../dtos/page-response.dto";
import { GenerateInvoiceDto, InvoiceDto } from "../dtos/invoice/invoice.dto";

@Injectable({
	providedIn: 'root'
})
export class InvoiceService {

	constructor(
		private http: HttpClient,
		private helperService: HelperService
	) { }

	generateInvoice(dto: GenerateInvoiceDto): Observable<InvoiceDto> {
		return this.http.post<InvoiceDto>(
			`${environment.urlApi}/invoice`,
			dto
		)
	}

	updateInvoice(dto: InvoiceDto) {
		return this.http.put<InvoiceDto>(
			`${environment.urlApi}/invoice/${dto.id}`,
			dto
		)
	}

	deleteInvoice(id: number) {
		return this.http.delete(
			`${environment.urlApi}/invoice/${id}`,
		);
	}

	getByFilters(params: InvoiceRequestParams): Observable<PageResponse<InvoiceDto>> {
		return this.http.get<PageResponse<InvoiceDto>>(
			`${environment.urlApi}/invoice/by-filter`,
			{ params: this.helperService.getObjectAsHttpParams(params) }
		)
	}

	generateInvoicePdf(invoiceId: number) {
		return this.http.get(
			`${environment.urlApi}/invoice/pdf/${invoiceId}`,
			{ observe: 'response', responseType: 'blob' }
		)
	}

}
