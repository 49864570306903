import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { catchError, map } from "rxjs";
import { UserService } from "../services/user.service";

export const accountExistsValidator = (userService: UserService): AsyncValidatorFn => {
	return (control: AbstractControl) => {
		return userService.emailAlreadyExists(control.value).pipe(
			map(response => response.exists ? null: { exists: true, message: 'Ce compte n\'éxiste pas.' }),
			catchError((err) => null)
		)
	}
}
