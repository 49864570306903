import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrl: './public.component.scss'
})

export class PublicComponent implements OnInit, OnDestroy {


	constructor(
		public router: Router
	) {}

	ngOnInit(): void {

	}

	ngOnDestroy(): void {

	}

}
