<!-- DESKTOP HOME -->
<div id="home-desktop" *ngIf="!appService.isMobile()">
	<div class="image-ctn">
		<img src="https://resources.fsasptt.com/jmactiv-entreprise/images/fond.jpg" />
		<div class="content">
			<h1>Bienvenue sur J'MACTIV ENTREPRISE !</h1>
			<p>En collaboration avec l'ASPTT Fédération Omnisports et le Groupe La Poste, nous lançons de nouvelles
				prestations dans la santé, le sport et le bien-être. Rejoignez-nous pour explorer ces offres, alignées
				avec la
				politique nationale de la DNAS et de la Direction des Ressources Humaines. Faites partie de notre
				mouvement pour
				promouvoir le bien-être et l'activité physique au travail. Votre santé et votre corps vous remercieront
				!</p>

			<div id="button-group">
				<div id="buttons">
					<button class="button is-info is-large" [ngClass]="{'is-fullwidth' : appService.isMobile()}"
						[routerLink]="['/inscription']">Créer un compte</button>
					<button class="button is-primary is-large" [ngClass]="{'is-fullwidth' : appService.isMobile()}"
						[routerLink]="['/connexion']">Je me connecte</button>
				</div>
				<div id="contact">
					<div>OU</div>
					<div id="link">
						<a target="_blank" [href]="environment.contactFormUrl" class="contact-link">Nous contacter</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- MOBILE HOME -->
<div id="home-mobile" *ngIf="appService.isMobile()">
	<div class="content">
		<h1>Bienvenue sur J'MACTIV ENTREPRISE !</h1>
		<p>En collaboration avec l'ASPTT Fédération Omnisports et le Groupe La Poste, nous lançons de nouvelles
			prestations dans la santé, le sport et le bien-être.
			<BR/><BR/>
			Rejoignez-nous pour explorer ces offres, alignées vec la politique nationale de la DNAS et de la Direction des Ressources Humaines.
			<BR/><BR/>
			Faites partie de notre mouvement pour promouvoir le bien-être et l'activité physique au travail. Votre santé et votre corps vous remercieront!
		</p>

		<div id="button-group">
			<div id="buttons">
				<button
					class="button is-info is-large is-fullwidth"
					[routerLink]="['/inscription']"
					>Créer un compte
				</button>
				<button
					class="button is-primary is-large is-fullwidth"
					[routerLink]="['/connexion']"
					>Je me connecte
				</button>
			</div>
			<div id="contact">
				<div>OU</div>
				<button class="button is-primary is-inverted is-large is-fullwidth">
					Nous contacter
				</button>

			</div>
		</div>
	</div>
</div>
