import {Pipe} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

export interface FieldConfig {
    type: FieldType;
    title?: string;
    fieldName?: string;
    hidden?: boolean;
    textAlign?: string;
    maxWidth?: string;
	minWidth?: string;
    param?: string;
	sortable?: boolean;
}


export interface FieldType {
    value: string;
    objectValue: any;
}

interface IPipe {
    pipe: Pipe;
    args?: any[];
}

// Text type
export class TextFieldType implements FieldType {
    objectValue: any;
    value = 'text';
    pipes: { pipe: Pipe, args?: any[] }[];
    chip: { color: ThemePalette };

    constructor(pipes?: { pipe: Pipe, args?: any[] }[], chip?: { color: ThemePalette }) {
        this.pipes = pipes;
        this.chip = chip;
    }
}

// Link button
export class LinkFieldType implements FieldType {
    objectValue: any;
    url: string;
    value = 'link';
    pipes: IPipe[];

    constructor(url: string, pipes?: IPipe[]) {
        this.url = url;
        this.pipes = pipes;
    }
}


// Button type
export class ButtonFieldType implements FieldType {
    objectValue: any;
    value = 'button';
    label: string;
    icon: string;
    iconRight: boolean;
    pipes: IPipe[];
    cssClass = 'link';
    propertyDisable: string;
    propertyGrey: string;
    align: string;
    secondary = false;

    constructor(public method: (params?: any) => void, pipes?: IPipe[], label?: string, icon?: string, cssClass?: string, iconRight?: boolean, propertyDisable?: string, propertyGrey?: string, secondary?: boolean) {
        this.label = label;
        this.pipes = pipes;

        if (icon) {
            this.icon = icon;
        }
        if (iconRight) {
            this.iconRight = iconRight;
        }
        if (cssClass) {
            this.cssClass = cssClass;
        }
        if (propertyDisable) {
            this.propertyDisable = propertyDisable;
        }
        if (propertyGrey) {
            this.propertyGrey = propertyGrey;
        }
        if (secondary) {
            this.secondary = secondary
        }
    }
}

// Menu with icons
export class MenuFieldType implements FieldType {
    objectValue: any;
    buttons: ButtonFieldType[];
    value = 'menu';

    constructor(buttons: ButtonFieldType[]) {
        this.buttons = buttons;
    }
}


// Link with function
export class LinkFunctionFieldType implements FieldType {
    objectValue: any;
    value = 'linkFunction';
    label?: string;
    propertyDisable: string;
    propertyGrey: string;

    constructor(public method: (params?: any) => void, label?: string, propertyDisable?: string, propertyGrey?: string) {
        if (label) {
            this.label = label;
        }
        if (propertyDisable) {
            this.propertyDisable = propertyDisable;
        }
        if (propertyGrey) {
            this.propertyGrey = propertyGrey;
        }
    }

    click(data) {
        this.method(data);
    }
}

// List type
export class ListFieldType implements FieldType {
    objectValue: any;
    value = 'list';
	styles;
	listOptions;

    constructor(styles?: any, options?: string[], public method?: (id, params?: any) => void) {
		this.styles = styles;
		this.listOptions = options;
    }

	click(data) {
		if (!!this.method) {
			this.method(data);
		}
    }
}

export class ImageFieldType implements FieldType {
    objectValue: any;
    value = 'image';
    width: string;
    height: string;
    align: string;

    constructor(width: string, height?: string) {
        this.width = width;
        if (height) {
            this.height = height
        }
    }
}

// Checkbox type
export class CheckboxFieldType implements FieldType {
    objectValue: any;
    value = "checkbox";

    constructor(public method: (params?: any) => void) {
    }
}


// Radio type
export class RadioFieldType implements FieldType {
    objectValue: any;
    value = "radio";

    constructor(public method: (params?: any) => void, public isChecked: (param?) => void) {
	}
}
