import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROLES } from '../../enums/roles.enum';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})

export class HeaderComponent{

	window = window;
	Role = ROLES;

	constructor(
		public authService: AuthService,
		public userService: UserService,
		public appService: AppService,
		private router: Router
	) { }

	onLogoClick() {
		!this.userService.currentUser()
			? this.router.navigate(["/"])
			: this.appService.logoClickCount.set(this.appService.logoClickCount()+1);

		if (this.appService.isMobile) {
			this.appService.menuIsOpen.set(false);
		}
	}

	toggleMenu() {
		this.appService.menuIsOpen.set(!this.appService.menuIsOpen());
	}

}
