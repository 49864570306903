<section class="container">
	<div class="header">
		<h1>Liste des demandes</h1>

		<form class="btn-group" [formGroup]="form">
			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>État de la commande</mat-label>
					<mat-select formControlName="bookingStatus" (selectionChange)="reloadContent()">
						<mat-option [value]="0">Tous les états</mat-option>
						<mat-option *ngFor="let key of Object.keys(BOOKING_STATUS_OBJ)" [value]="key">
							{{BOOKING_STATUS_OBJ[key].label}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="custom-field" style="max-width: 280px; width: 280px;">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Région</mat-label>
					<mat-select formControlName="regionId" (selectionChange)="regionChanged()">
						<mat-option [value]="0">Toutes les régions</mat-option>
						<mat-option *ngFor="let region of userService.currentUser().regionalCommittees" [value]="region.id">
							{{region.title}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="custom-field"  style="max-width: 500px; width: 500px;">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Client</mat-label>
					<mat-select formControlName="clientId" (selectionChange)="reloadContent()">
						<mat-option [value]="0">Tous les clients</mat-option>
						<mat-option *ngFor="let customer of customers" [value]="customer.id">
							{{customer?.companyName.toUpperCase()}} ({{customer?.city}}) -
							{{customer?.fullName}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</form>
	</div>

	<p id="no-response" *ngIf="pageResponse?.totalElements === 0">Aucune demande pour l'instant.</p>

	<div class="box" *ngIf="pageResponse?.totalElements > 0">

		<mat-paginator
			*ngIf="pageResponse?.totalPages > 1"
			[length]="pageResponse?.totalElements"
			[pageSize]="pageResponse.size"
			[pageSizeOptions]="[10, 20, 50, 100]"
			[pageIndex]="pageResponse?.number" (page)="pageChanged($event)">
		</mat-paginator>

		<div id="table-container">
			<table class="table is-striped" *ngIf="pageResponse?.content.length > 0">
				<thead>
					<tr>
						<td style="width: 100px">Référence devis</td>
						<td style="width: 100px">Date</td>
						<td style="width: 150px">Région</td>
						<td style="width: 200px">Nom entreprise</td>
						<td style="width: 150px">Nom du contact</td>
						<td style="width: 120px">Nb prestations</td>
						<td style="width: 100px">Montant devis</td>
						<td style="width: 170px">État de la demande</td>

					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let booking of pageResponse.content">
						<td><button class="button is-primary is-inverted" (click)="router.navigateByUrl('/dashboard/demandes/'+booking.id)">#{{booking.proposalReference}}</button></td>
						<td>{{helperService.dateFr(booking.date)}}</td>
						<td>{{booking.region}}</td>
						<td>{{booking.companyName}}</td>
						<td>{{booking.contactName}}</td>
						<td>{{booking.nbEvents}}</td>
						<td>{{booking.amountProposal}}</td>
						<td>
							<div class="status" [ngClass]="booking.bookingStatus">
								{{BOOKING_STATUS_OBJ[booking.bookingStatus].label}}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<mat-paginator *ngIf="pageResponse?.totalPages > 1"
				[length]="pageResponse?.totalElements"
				[pageSize]="pageResponse.size"
				[pageSizeOptions]="[10, 20, 50, 100]"
				[pageIndex]="pageResponse?.number"
				(page)="pageChanged($event)">
		</mat-paginator>
	</div>

</section>
