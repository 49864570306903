import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ReservationSlotDto } from 'src/app/modules/shared/dtos/event/event.dto';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { EventService } from 'src/app/modules/shared/services/event.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';


@Component({
	selector: 'app-reservation-form.modal',
	templateUrl: './reservation-form.modal.html',
	styleUrls: ['./reservation-form.modal.scss'],
})

export class ReservationFormModal implements OnInit {

	slot: ReservationSlotDto;
	form: FormGroup;

	constructor(
		public router: Router,
		public helper: HelperService,
		public appService: AppService,
		private formBuilder: FormBuilder,
		private eventService: EventService,
		private dialogRef: DialogRef
	) { }

	ngOnInit() {
		this.slot = this.dialogRef.config.data.slot;
		this.form = this.formBuilder.group({
			slotId: [this.slot.id, [Validators.required]],
			guestFirstName: [null, [Validators.required]],
			guestLastName: [null, [Validators.required]],
		})
	}

	async submit() {
		await firstValueFrom(this.eventService.bookSlot(this.form.getRawValue()));
		this.router.navigateByUrl('reservation-success');
		this.dialogRef.close()
	}

}
