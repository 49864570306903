import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { SharedModule } from "../shared/shared.module";
import { RequestRecoveryComponent } from "./modals/request-recovery/request-recovery.component";
import { ConnexionComponent } from './screens/connexion/connexion.component';
import { ErreurComponent } from "./screens/erreur/erreur.component";
import { HomeMainComponent } from './screens/home/home-main.component';
import { InscriptionComponent } from './screens/inscription/inscription.component';
import { ReinitialiserMotDePasseComponent } from './screens/reinitialiser-mot-de-passe/reinitialiser-mot-de-passe.component';
import { ReserverComponent } from "./screens/reserver/reserver.component";
import { SlotBookingSuccessComponent } from "./screens/slot-booking-success/slot-booking-success.component";
import { ReservationFormModal } from "./screens/reserver/reservation-form-modal/reservation-form.modal";
import { PublicComponent } from "./public.component";

@NgModule({
	declarations: [
		PublicComponent,
		HomeMainComponent,
		InscriptionComponent,
		ConnexionComponent,
		ReinitialiserMotDePasseComponent,
		RequestRecoveryComponent,
		ErreurComponent,
		ReserverComponent,
		SlotBookingSuccessComponent,
		ReservationFormModal
	],
	imports: [
		RouterModule.forChild([
			{ path: '', component: HomeMainComponent },
			{ path: 'reserver/:eventId', component: ReserverComponent },
			{ path: 'reservation-success', component: SlotBookingSuccessComponent },
			{ path: 'inscription', component: InscriptionComponent },
			{ path: 'connexion', component: ConnexionComponent },
			{ path: 'reset-password', component: ReinitialiserMotDePasseComponent },
			{ path: 'erreur', component: ErreurComponent }
	]),
		NgxMaskDirective,
		NgxMaskPipe,
		SharedModule,
	],
	providers: []
})

export class PublicModule { }
