export const JOBS = [
	"Agent(e) de tri postal",
	"Analyste en données de livraison",
	"Analyste financier",
	"Cadre intermédiaire",
	"Cadre supérieur(e)",
	"Chauffeur(euse) de camion de livraison",
	"Chef(fe) de projet logistique",
	"Dirigeant(e)",
	"Employé(e) de bureau",
	"Employé(e) polyvalent",
	"Employé(e) technique",
	"Expert(e) en sécurité",
	"Facteur(trice)",
	"Gestionnaire de la qualité",
	"Gestionnaire de projet",
	"Gestionnaire de relation client",
	"Guichetier(ère)",
	"Informatique et technologie de l'information",
	"Juridique",
	"Juriste d'entreprise",
	"Logistique et chaîne d'approvisionnement",
	"Manutentionnaire",
	"Manager de zone de distribution",
	"Qualité et assurance qualité",
	"Recherche et développement",
	"Responsable de bureau de poste",
	"Responsable de la flotte de véhicules",
	"Responsable des ressources humaines",
	"Responsable du marketing postal",
	"Ressources humaines",
	"Service client",
	"Spécialiste en développement durable",
	"Technicien(ne)",
	"Travailleur de production",
	"Ventes et marketing"
]
